import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServerService } from '../services/server.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import { NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { truncate } from 'fs';
declare var $: any;
declare var iziToast: any;
@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
	chattime: any;
	hasClass(el: any) {
		// alert(el)
		return !(el.getAttribute('class') !== -1);
	}
	@ViewChild('chat_message', { static: false }) chat_message: ElementRef;
	@ViewChild('chat_detail_id', { static: false }) chat_detail_id: ElementRef;
	chat_panel_list;
	chat_panel_details;
	chat_panel_detail_type = "chat_screen";
	loginUser;
	chat_detail_key;
	customer_name;
	socketData;
	websocket;
	profile_image;
	chat_id;
	chat_status_detail_id;
	param1
	c_status;
	// botlisting;
	public is_chat_closed = false;
	widget_name = '';
	temp_list;
	adminid;
	doc_link;
	chatdate = false;
	data_view = 'active';
	search_text = '';
	//NB COde....
	select_agents: any;
	select_department: any;
	agent_options;
	email_dept;
	transfer_id;
	current_chat_id = 0;
	bot_status = false;
	select_mail_agent: any;
	all_agent_list;
	chat_agent_name;
	chat_department;
	chat_status_name;
	chat_roundrobin;
	admin_permission;
	user_type;
	next_assign_for;
	//NB COde END....
	assigned_user_perm;
	constructor(public serverService: ServerService, private _ngZone: NgZone, private route: ActivatedRoute) {

		this.param1 = this.route.snapshot.queryParamMap.get('c');
		// this.serverService.deptmt_reload.subscribe((data)=>{
		// 	let mData = JSON.parse(data);
		// 	if(mData.action=="reload_list" && localStorage.getItem('chat_queue')== '1'){
		// 		//alert('iywd')
			 
		// 			setTimeout(() => {
		// 				this.chatPanelView('all');
		// 				this.chat_panel_detail_type = "chat_screen";
		// 			}, 2500)
		// 			localStorage.removeItem('chat_queue')
				 
		// 	}
		// })
		this.serverService.transfer_reload.subscribe((data) => {
			console.log(data);
			let mData = JSON.parse(data);
			if (mData.datas == 'reload_transfer' && localStorage.getItem('userId') == mData.ext_user_id) {
				this.chatPanelView('all');
				this.chat_panel_detail_type = "chat_screen";
			}
			// if(mData.datas=='reload_transfer'&&localStorage.getItem('userId')==mData.user_id[0]){
			// 	alert('test');
			// 	this.chatPanelView('all');
			// 	  this.chat_panel_detail_type = "chat_screen";
			// }
		})

		this.serverService.changeDetectionEmitter.subscribe(
			($event) => {

				let mData = JSON.parse($event);
				console.log(mData)
				var pagefor = mData.pagefor;
				var chatid = mData.id;
				chatid = atob(chatid);


				if (pagefor == 'webchat') {
					// this.chatPanelDetail(pageid);			
					// this.chatPanelView(pageid);
					//   alert(chatid)
					//   alert(this.chat_detail_key)



					if (this.chat_detail_key == chatid)
						//   this.chatPanelDetail(chatid,this.c_status);
						this.notifyChatPanel(chatid, this.c_status);
					else
						this.chatPanelView2("all");


					// setTimeout(()=>{ 
					//   $(".card-body.chat-content").scrollTop($(".card-body.chat-content")[0].scrollHeight);
					//   }, 4000);
					// alert('asas')
				}

			},
			(err) => {
			}
		);
	}

	ngOnInit() {
		this.loginUser = localStorage.getItem('userId');
		this.adminid = localStorage.getItem('admin_id');
		this.user_type = localStorage.getItem('user_type');
		this.admin_permission = localStorage.getItem('admin_permision');
		this.chat_roundrobin = localStorage.getItem('chat_roundrobin');
		if (this.param1) {
			this.param1 = atob(this.param1);
			this.chatPanelView(this.param1);
			// this.chatPanelDetail(this.param1,this.c_status)
		} else {
			this.chatPanelView("all");
			// alert('asass12345678')
		}


		if (this.user_type == 'Super Admin') {
			this.user_type = 1;
		}
		else if (this.user_type == 'Admin' || this.admin_permission == '1') {
			this.user_type = 2;
		}
		else {
			this.user_type = 3;
		}

		this.ChatSocket();
		this.get_temps();
		this.getAllAgentsList();
	}
	send_chat_reload_data(data) {
		this.websocket.send(data);
	}
	ChatSocket() {
		var self = this;
		this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4004");


		this.websocket.onopen = function (event) {
			console.log('socket chat connected');

		}

		this.websocket.onmessage = function (event) {


			this.socketData = JSON.parse(event.data);
			console.log(this.socketData);
			console.log(this.socketData.message_type);
			if (this.socketData.action == 'reload_list') {
				let mData = this.socketData;
				// this.send_chat_reload_data(mData)
			 
				self.chatPanelView('all');
				 
				 
				 	if(mData.action=="reload_list" && localStorage.getItem('chat_queue')== '1'){
						setTimeout(() => {
							self.chatPanelView('all');
							self.chat_panel_detail_type = "chat_screen";
						}, 2500)
						localStorage.removeItem('chat_queue')
					}

				 

				return false;

			}

			if (this.socketData.action == 'reload_dtp_data') {
				let mData = this.socketData;
				// this.send_chat_reload_data(mData)
				let dept_ids = mData.dtp_users.split(',');
				self.chatPanelView('all');
				dept_ids.forEach(element => {
					// self.chatPanelView('all');
					if (element == localStorage.getItem('userId') || localStorage.getItem('chat_queue')== '1')  {
						setTimeout(() => {
							self.chatPanelView('all');
							self.chat_panel_detail_type = "chat_screen";
						}, 2500)
						localStorage.removeItem('chat_queue')
					}

				});

				return false;

			}


			if (this.socketData.message_type == "chat") {
				console.log('test chat')
				if (this.socketData.message_status == "end") {
					if (this.socketData.message_info.chat_id == $('#chat_detail_id').val()) {

						setTimeout(() => {
							console.log('sec 7')
							self.chatPanelView("all");
						}, 7000)

						// return false;
					}

					if (localStorage.getItem('user_type') == 'Admin' || localStorage.getItem('admin_permision') == '1') {
						// this.data_view = 'queue';
						// if(self.data_view == 'queue'){

						setTimeout(() => {
							console.log('sec 7 Admin')
							self.chatPanelView("all");
						}, 7000)

						// }
						return false;
					}

				}
				if (this.socketData.message_status == "active_reload") {

					console.log('test active')

					if (this.socketData.message_info.chat_id == $('#chat_detail_id').val()) {
						console.log('active_reload');
						setTimeout(() => {
							console.log('sec 7')
							self.chatPanelView("all");
						}, 7000)

						// return false;
					}

					if (localStorage.getItem('user_type') == 'Admin' || localStorage.getItem('admin_permision') == '1') {
						// this.data_view = 'queue';
						// if(self.data_view == 'queue'){

						console.log('active_reload2');
						setTimeout(() => {
							console.log('sec 7 Admin')
							self.chatPanelView("all");
						}, 7000)

						// }
						return false;
					}

				}


				if (this.socketData.message_status == "closedbyAgent") {
					// if(self.data_view == 'queue'){

					setTimeout(() => {
						console.log('sec 1.5 Admin')
						self.chatPanelView("all");
					}, 2500)

					// }
					return false;
				}

			}

			// 			if (this.socketData.message_info.chat_id == $('#chat_detail_id').val()) {
			// // alert('2')

			// 				//this.chatPanelDetail(this.socketData.message_info.chat_id);
			// 				$('#open_chat_detail_id').val(this.socketData.message_info.chat_id);
			// 				$('#open_chat_detail_id').click();

			// 			} else {
			// // alert('3')

			// 				$('#chatPanelView').click();
			// 			}

			// 		}

		}
		this.websocket.onerror = function (event) {
			console.log('error');
		}
		this.websocket.onclose = function (event) {
			console.log('close');
			self.ChatSocket();
		}
	}
	// ngAfterViewInit() {
	// 	this.chatautoScroll();
	// }

	chatautoScroll() {

		// if ($(".card-body.chat-content").length > 0) {
		setTimeout(() => {
			$(".card-body.chat-content").scrollTop($(".card-body.chat-content")[0].scrollHeight);

		}, 10);
		// }

	}


	chatSearch(chatSearch) {

	}

	sendChatMessageData() {

		this.profile_image = localStorage.getItem('profile_image');


		if (this.profile_image == null || this.profile_image == 'null' || this.profile_image == 'undefined') {
			this.profile_image = 'https://assaabloycc.mconnectapps.com/api/v1.0/profile_image/user.jpg';
		} else {
			this.profile_image = localStorage.getItem('profile_image');
		}
		var chat_message = this.chat_message.nativeElement.value;
		chat_message = chat_message.trim();
		if (chat_message.length > 0) {



			let api_req: any = new Object();
			let chat_req: any = new Object();
			chat_req.action = "send_chat_message";
			chat_req.chat_type = "webchat";
			chat_req.chat_id = this.chat_detail_id.nativeElement.value;
			chat_req.user_id = this.loginUser;
			chat_req.chat_message = btoa(chat_message);
			api_req.operation = "chat";
			api_req.moduleType = "chat";
			api_req.api_type = "web";
			api_req.access_token = localStorage.getItem('access_token');
			api_req.element_data = chat_req;

			this.serverService.sendServer(api_req).subscribe((response: any) => {

				if (response.result.status == 1) {

					var chat_msg = response.result.data;
					let agent_name = localStorage.getItem('user_name');
					var socket_message = '{"message_type":"chat","message_status":"existing","message_info" : {"chat_id" : "' + chat_msg.chat_id + '","msg_user_id" : "' + chat_msg.msg_user_id + '","msg_user_type" : "2","msg_type":"text","message" : "' + chat_msg.chat_msg + '","queue_id":"1","agent_aviator":"' + this.profile_image + '","agent_name":"' + agent_name + '"}}';

					this.websocket.send(socket_message);


					this.chat_panel_details.push(chat_msg);
					this.chatautoScroll();
					$('#chat_msg').val('');
				}

			},
				(error) => {
					console.log(error);
				});

		}

	}


	// readFile(fileEvent: any) {
	// 	const file = fileEvent.target.files[0];
	// 	if (file.type ==)

	// 	console.log('size', file.size);
	// 	console.log('type', file.type);
	//  }


	textUrl(text) {
		var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
		//var urlRegex = /(https?:\/\/[^\s]+)/g;
		var html = text.replace(urlRegex, function (url, b, c) {
			var url2 = (c == 'www.') ? 'http://' + url : url;
			return '<a class="hyperlink-text" href="' + url2 + '" target="_blank">' + url + '</a>';
		})
		console.log(html);
		return html
	}



	sendImage() {

		var chat_message = this.chat_message.nativeElement.value;

		// var new_chat_msg = this.textUrl(chat_message);
		var new_chat_msg = this.textUrl(chat_message).replace(/'/g, "\\'");
		//new_chat_msg=new_chat_msg.replace(/["']/g, "");

		$("#createNewWidget").modal('hide');



		this.profile_image = localStorage.getItem('profile_image');


		if (this.profile_image == null || this.profile_image == 'null' || this.profile_image == 'undefined') {
			this.profile_image = 'https://assaabloycc.mconnectapps.com/api/v1.0/profile_image/user.jpg';
		} else {
			this.profile_image = localStorage.getItem('profile_image');
		}

		// var chat_message = this.chat_message.nativeElement.value;
		// chat_message = chat_message.trim();
		// if (chat_message.length > 0) {

		if (((<HTMLInputElement>document.getElementById('chat_media')).value == null || (<HTMLInputElement>document.getElementById('chat_media')).value == '') && (new_chat_msg == '' || new_chat_msg == null)) {
			iziToast.warning({
				message: "No input found",
				position: "topRight"
			});
			return false;
		}
		Swal.fire({
			title: 'Please Wait',
			allowEscapeKey: false,
			allowOutsideClick: false,
			//  background: '#19191a',
			showConfirmButton: false,
			onOpen: () => {
				Swal.showLoading();
			}
		});
		var chats_ids = this.chat_detail_id.nativeElement.value;
		var loginUser = this.loginUser;

		var form = new FormData();
		var self = this;
		if ((<HTMLInputElement>document.getElementById('chat_media')).value != null) {
			// 	var totalfiles = (<HTMLInputElement>document.getElementById('chat_media')).files.length;
			// 	for (var index = 0; index < totalfiles; index++) {
			// 		form.append("up_files[]", (<HTMLInputElement>document.getElementById('chat_media')).files[index]);
			// 	}
			form.append("up_files", (<HTMLInputElement>document.getElementById('chat_media')).files[0]);
		}

		form.append("chat_id", chats_ids);
		form.append("chat_type", "webchat");
		form.append("user_id", loginUser);
		// form.append("chat_message", btoa(new_chat_msg));
		// form.append("chat_msg", btoa(new_chat_msg));
		form.append("chat_message", new_chat_msg);
		form.append("chat_msg", new_chat_msg);
		form.append("timezone_id", "1");
		form.append("action", "send_chat_message");
		form.append("access_token", localStorage.getItem('access_token'));
		// form.append("up_files", fileInput.files[0], "/C:/Users/mdras/Desktop/ddd.png");
		// form.append("up_files", fileInput.files[0], "/C:/Users/mdras/Desktop/download.jfif");
		// form.append("up_files", fileInput.files[0], "/C:/Users/mdras/Desktop/matrix-failed.png");

		var settings = {
			"url": "https://assaabloycc.mconnectapps.com/api/v1.0/index_new.php",
			"method": "POST",
			"timeout": 0,
			"headers": {
				"Authorization": "Basic TWVyY2hhbnQuVEVTVDEwMDAwMDI0NTA0OjQ1NjVhOTI4NGQ0ZjFkMjE3YzI5OTY5ZGUxNTc1YzM2"
			},
			"processData": false,
			"mimeType": "multipart/form-data",
			"contentType": false,
			"data": form
		};
		// 		setTimeout(() => {
		// Swal.close();

		// 		}, 2000);
		$.ajax(settings).done(function (response) {
			Swal.close();
			var newone = JSON.parse(response);
			console.log(newone.data);
			if (newone.status == 'true') {
				self.clearFileUploadingField();
				var chat_msg = newone.data;
				console.log(self.chat_panel_details);
				//let agent_name = localStorage.getItem('user_name');
				var str = chat_msg.agent_name;
				var agent_name = str.split(" ");
				//var conversion_url =window.btoa(chat_msg.chat_msg);
				//...
				//var conversion_url = btoa(unescape(encodeURIComponent(chat_msg.chat_msg)));
				var conversion_url = chat_msg.chat_msg;
				//console.log(conversion_url)
				conversion_url = conversion_url.replace('"', "\'");
				console.log(conversion_url)
				var socket_message = '{"message_type":"chat","message_status":"existing","message_info" : {"chat_id" : "' + chat_msg.chat_id + '","msg_user_id" : "' + chat_msg.msg_user_id + '","msg_user_type" : "2","msg_type":"text","message" : "' + btoa(conversion_url) + '","queue_id":"1","agent_aviator":"' + self.profile_image + '","agent_name":"' + agent_name[0] + '","chat_images":"' + chat_msg.chat_images + '", "extension":"' + chat_msg.extension + '"}}';

				self.websocket.send(socket_message);

				self.chat_panel_details.push(chat_msg);

				self.chatautoScroll();
				$('#chat_msg').val('');
			} else {
				iziToast.error({
					message: "Sorry, The message is Delivered",
					position: 'topRight'
				});
			}

		});

		// }

	}


	clearFileUploadingField() {
		var input = $("#chat_media");
		input.replaceWith(input.val('').clone(true));
	}

	onMessageSend($event) {

		if ($event.keyCode == 13) {


			// this.sendChatMessageData();
			this.sendImage();
			$event.stopPropagation();
			return false;
		}


	}

	chatPanelView(chat_id) {
		// {"action":"chat_message_panel","chat_type":"webchat","chat_id":"all","user_id":"1203","status":"2"}}

		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "chat_message_panel";
		chat_req.chat_type = "webchat";
		chat_req.chat_id = chat_id;
		console.log(this.adminid);
		if (this.admin_permission == 1)
			chat_req.user_id = this.adminid;
		else
			chat_req.user_id = this.loginUser;


		if (this.data_view == 'close') {
			// if ($('#voice_3cx').prop('checked') == true) {

			chat_req.status = '2';
			this.is_chat_closed = true;

		} else if (this.data_view == 'active') {
			// alert('not check')
			chat_req.status = '5';
			this.is_chat_closed = false;

		}
		else if (this.data_view == 'missed') {
			chat_req.status = '6';
			this.is_chat_closed = true;
		} else {
			chat_req.status = '1';
			this.is_chat_closed = false;
		}
		api_req.operation = "chat";
		api_req.moduleType = "chat";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;

		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.result.status == 1) {
            localStorage.setItem('chat_queue', '1');
				this.chat_panel_list = response.result.data.chat_list;
				if (this.chat_panel_list.length)
					this.c_status = response.result.data.chat_list[0].chat_status;


				if (chat_id == "all" || chat_id == "" || chat_id == 0) {
					this.chat_panel_detail_type = "chat_screen";
				}
				else {

					this.chat_panel_details = response.result.data.chat_detail_list;
					this.chat_panel_detail_type = "chat_detail";
					this.chatPanelDetail(chat_id, this.c_status)
				}


				// this.chatautoScroll();
				this.chat_detail_key = chat_id;
			}

		},
			(error) => {
				console.log(error);
			});


	}
	tabsection(click_type) {
		if (click_type == 'active') {
			this.data_view = 'active';
			//$('#voice_3cx').prop('checked', false)
			$('#sect2').removeClass('tab-active');
			$('#sect3').removeClass('tab-active');
			$('#sect4').removeClass('tab-active');
			$('#sect1').addClass('tab-active');
			this.chatPanelView('all');
		} else if (click_type == 'close') {
			this.data_view = 'close';
			//$('#voice_3cx').prop('checked', true)
			$('#sect1').removeClass('tab-active');
			$('#sect3').removeClass('tab-active');
			$('#sect4').removeClass('tab-active');
			$('#sect2').addClass('tab-active');
			this.chatPanelView('all');
		}
		else if (click_type == 'missed') {
			this.data_view = 'missed';
			$('#sect1').removeClass('tab-active');
			$('#sect2').removeClass('tab-active');
			$('#sect4').removeClass('tab-active');
			$('#sect3').addClass('tab-active');
			this.chatPanelView('all');


		}
		else if (click_type == 'queue') {
			this.data_view = 'queue';
			$('#sect1').removeClass('tab-active');
			$('#sect2').removeClass('tab-active');
			$('#sect3').removeClass('tab-active');
			$('#sect4').addClass('tab-active');
			this.chatPanelView('all');
		}
	}
	chatPanelView2(chat_id) {
		// {"action":"chat_message_panel","chat_type":"webchat","chat_id":"all","user_id":"1203","status":"2"}}

		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "chat_message_panel";
		chat_req.chat_type = "webchat";
		chat_req.chat_id = chat_id;
		if (this.admin_permission == 1)
			chat_req.user_id = this.adminid;
		else
			chat_req.user_id = this.loginUser;

		if (this.search_text != '' && this.search_text != null && this.search_text != undefined)
			chat_req.search_text = this.search_text;
		if (this.data_view == 'close') {
			// if ($('#voice_3cx').prop('checked') == true) {

			chat_req.status = '2';
			this.is_chat_closed = true;

		} else if (this.data_view == 'active') {
			// alert('not check')
			chat_req.status = '5';
			this.is_chat_closed = false;

		}
		else if (this.data_view == 'missed') {
			chat_req.status = '6';
			this.is_chat_closed = true;
		} else {
			chat_req.status = '1';
			this.is_chat_closed = false;

		}
		api_req.operation = "chat";
		api_req.moduleType = "chat";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;
		// alert('3')
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.result.status == 1) {

				this.chat_panel_list = response.result.data.chat_list;
				this.c_status = response.result.data.chat_list[0].chat_status;

				// if (chat_id == "all" || chat_id == "" || chat_id == 0) {
				// 	this.chat_panel_detail_type = "chat_screen";
				// }
				// else {
				// 	this.chat_panel_details = response.result.data.chat_detail_list;
				// 	this.chat_panel_detail_type = "chat_detail";
				// 	this.chatPanelDetail(chat_id, this.c_status)
				// }


				// this.chatautoScroll();
				// this.chat_detail_key = chat_id;
			}

		},
			(error) => {
				console.log(error);
			});


	}

	chatPanelList(search_text) {
		this.search_text = search_text;
		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "chat_message_panel";
		chat_req.chat_type = "webchat";
		chat_req.chat_id = 'all';

		if (this.admin_permission == 1)
			chat_req.user_id = this.adminid;
		else
			chat_req.user_id = this.loginUser;

		chat_req.search_text = search_text;
		if (this.data_view == 'close') {
			// if ($('#voice_3cx').prop('checked') == true) {		 
			chat_req.status = '2';

		} else if (this.data_view == 'active') {
			chat_req.status = '5';
		}
		else if (this.data_view == 'missed') {
			chat_req.status = '6';

		} else {
			chat_req.status = '1';
		}
		api_req.operation = "chat";
		api_req.moduleType = "chat";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;

		// {"operation":"chat","moduleType":"chat","api_type":"web","access_token":"","element_data":{"action":"chat_message_panel","chat_type":"webchat","chat_id":"all","user_id":"","search_text":"","status":""}}

		this.serverService.sendServer(api_req).subscribe((response: any) => {

			if (response.result.status == 1) {


				this.chat_panel_list = response.result.data.chat_list;
			}
		},
			(error) => {
				console.log(error);
			});


	}

	chatPanelDetail(chat_id, c_status) {
		$('#chat_msg').val('');
		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "chat_detail_list";
		chat_req.chat_type = "webchat";
		chat_req.chat_id = chat_id;
		chat_req.user_id = this.loginUser;
		api_req.operation = "chat";
		api_req.moduleType = "chat";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',
		});
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			if (response.result.status == 1) {
                this.next_assign_for = response.result.data.chat_detail_list[0].next_assign_for;
				if (c_status == '2') {
					this.chat_status_detail_id = 'closed';
					this.is_chat_closed = true;
				} else {
					this.is_chat_closed = false;
				}

				this.chat_panel_detail_type = "chat_detail";
				this.chat_panel_details = response.result.data.chat_detail_list;
				this.customer_name = response.result.data.chat_detail_list[0].customer_name;
				this.widget_name = response.result.data.chat_detail_list[0].widget_name;
				this.current_chat_id = response.result.data.chat_detail_list[0].chat_id;
				// var testdata = response.result.data.chat_detail_list[0].chat_msg;

				// if(response.result.data.chat_detail_list[0].chat_time == "0000-00-00 00:00:00"){
				// 	this.chatdate =true;
				this.chattime = response.result.data.chat_date;
				// 	console.log(this.chattime)
				// }
				this.chat_department = response.result.data.chat_detail_list[0].department_name;
				this.chat_status_name = response.result.data.chat_detail_list[0].chat_status_name;




				// this.botlisting = testdata.split('||');
				if (response.result.data.chatbot_status == 0) {
					this.bot_status = true;
				}
				else {
					this.bot_status = false;
				}
				this.chatautoScroll();
				this.chat_detail_key = chat_id;
				this.chatPanelView2('all');
				//Transfer popup predefined values
				this.WidgetdeptList(response.result.data.chat_detail_list[0].department, response.result.data.chat_detail_list[0].assigned_user)

				//	alert(this.chat_detail_key)
				//To disable while closed
				var chat_origin = response.result.data.chat_detail_list[0].chat_status;
				if (chat_origin == 2 || chat_origin == 6)
					this.is_chat_closed = true;

				console.log(response.result.data.chat_detail_list[0].assigned_user.split(','))
				//Get Agent Name
				if (this.c_status != 1)
					this.chat_agent_name = this.getAgentByID(response.result.data.chat_detail_list[0].assigned_user)
					
			}

		},
			(error) => {
				console.log(error);
			});


	}



	deletedata(id) {

		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'END Chat!'
		}).then((result) => {
			if (result.value) {
				// new code 11-07-2021
				let agent_name = localStorage.getItem('user_name');
				var socket_message = '{"message_type":"chat","message_status":"existing","message_info" : {"chat_id" : "' + id + '","msg_user_id" : "' + this.loginUser + '","msg_user_type" : "3","msg_type":"text","message" : "closed","queue_id":"1","agent_aviator":"' + this.profile_image + '","agent_name":"' + agent_name + '"}}';

				this.websocket.send(socket_message);
				let access_token: any = localStorage.getItem('access_token');
				let admin_id: any = localStorage.getItem('admin_id');
				// let api_req: any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"chat_details","chat_id":"' + id + '","user_id":"' + admin_id + '","widget_name":"' + this.widget_name + '"}}';


				let api_req: any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"chat_closedby_user","chat_id":"' + id + '","user_id":"' + admin_id + '","widget_name":"' + this.widget_name + '"}}';

				Swal.fire({
					html:
						'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
					showCloseButton: false,
					showCancelButton: false,
					showConfirmButton: false,
					focusConfirm: false,
					background: 'transparent',
				});
				this.serverService.sendServer(api_req).subscribe((response: any) => {
					Swal.close();
					if (response.status == true) {
						Swal.fire(
							'Closed!',
							'success'
						);
						this.chatPanelView("all");

						var chatClosedData = '{"message_type":"chat","message_status":"closedbyAgent","message_info" : {"chat_id" : "' + id + '","admin_id" : "' + admin_id + '"}}';

						this.websocket.send(chatClosedData);

						setTimeout(() => {
							this.chat_panel_detail_type = "chat_screen";
						}, 2000);
					}

				},
					(error) => {
						console.log(error);
					});
			}
		})
	}

	get_temps() {
		let access_token: any = localStorage.getItem('access_token');

		let api_req: any = '{"operation":"template", "moduleType":"template", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"listTemplateByUSer","admin_id":"' + this.adminid + '","user_id":"' + this.loginUser + '"}}';

		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.result.status == true) {

				this.temp_list = response.result.data;
				//   console.log(this.temp_list);
			}
		},
			(error) => {
				console.log(error);
			});
	}



	template() {
		var options = {};
		$.map(this.temp_list,
			function (o) {
				options[o.template_message] = o.template_name;
			});

		const fruit = Swal.fire({
			title: 'Select Template',
			input: 'select',
			inputOptions: options,
			inputPlaceholder: 'Select a Template',

			confirmButtonText: 'Pick out',
			showCancelButton: true,
		}).then(function (inputValue) {
			if (inputValue) {
				console.log(inputValue.value);
				console.log(fruit);
				$('#chat_msg').val(inputValue.value);
			}
		});


	}

	showdoc(link) {
		//   this.doc_link=link;
		//  $("#document_model").modal('show');   
		var url = link.split('/');
		// alert(url)
		this.doc_link = "https://www.youtube.com/embed/" + url[3];
		// alert(this.doc_link)

		$("#video_play").modal('show');

	} stop() {
		var el_src = $('.myvideo').attr("src");
		$('.myvideo').attr("src", el_src);
	}



	// =================================== CHAT TRANSFER =============================================

	transferpopup(ids) {
		$('#chatTransfer').modal('show');
		this.transfer_id = ids;
		// this.WidgetdeptList();
	}

	TransferChatAgent(agent, dept) {

		console.log(agent, dept);
		// alert(this.assigned_user_perm)
		if (this.assigned_user_perm == agent) {
			iziToast.warning({
				message: "Chat already assinged",
				position: "topRight"
			});
			return false;
		}
		// chatTransfer -> user_id & chat_id
		let access_token: any = localStorage.getItem('access_token');

		let api_req: any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"chatTransfer","chat_id":"' + this.transfer_id + '","dept_id":"' + dept + '","user_id":"' + agent + '","admin_id":"' + this.adminid + '","login_user":"' + this.loginUser + '"}}';
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',
		});

		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			$('#chatTransfer').modal('hide');
			if (response.status == true) {
				if (response.result.data == 5) {
					Swal.fire({
						title: 'This user already has a limited Chat',
						text: "Round robin strategy will not allow you to exceed the limit",
						icon: 'info',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'Got it!'
					});
				} else {

					iziToast.success({
						message: "Transferred successfully!",
						position: 'topRight'
					});
					if (this.user_type == 2)
						this.chat_panel_details.push(response.result.data);
					else
						this.chatPanelView("all");
					this.chatPanelView2("all");
					this.chat_panel_detail_type = "chat_screen";
					let transfer_to = this.getAgentName(agent);
					let agent_name = localStorage.getItem('agent_name');
					var transfer_text = "" + agent_name + " Transfered the chat to " + transfer_to + "";

					var socket_message = '{"message_type":"chat","message_status":"transfer_existing","message_info" : {"chat_id" : "' + this.current_chat_id + '","admin_id" : "' + this.adminid + '","msg_type":"text","message" : "' + transfer_text + '"}}';
					console.log(socket_message);
					this.websocket.send(socket_message);
				}
			} else {
				iziToast.error({
					message: "Transferred Failed!",
					position: 'topRight'
				});
			}
			this.chatPanelView("all");
			this.chatPanelView2("all");

		},
			(error) => {
				console.log(error);
			});

	}



	WidgetdeptList(dept, user) {
		// {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"get_dept_settings","user_id":"1203"}}
		// alert(this.chat_detail_key)
		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "get_chat_department";
		chat_req.chat_id = this.chat_detail_key;
		chat_req.admin_id = this.adminid;
		api_req.operation = "web_chat";
		api_req.moduleType = "web_chat";
		api_req.api_type = "web_chat";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			console.log(response);
			if (response.status == "true") {
				this.email_dept = response.options;
				//This will just select the default dropdown Value
				setTimeout(() => {
					this.select_department = dept;
					// alert(this.select_department)
					if (this.select_department) {
						this.get_agents_by_department_noti(this.select_department, user);
					}
				}, 2000);

			}
		},
			(error) => {
				console.log(error);
			});

	}

	get_agents_by_department(dept_ids, assigned_user) {
		var data = dept_ids;
		// alert(data)

		let access_token: any = localStorage.getItem('access_token');
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',
		});

		let api_req: any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ1YXRhc3NhYWJsb3ljY2FwaS5tY29ubmVjdGFwcHMuY29tIiwiYXVkIjoidWF0YXNzYWFibG95Y2NhcGkubWNvbm5lY3RhcHBzLmNvbSIsImlhdCI6MTY3ODMzNjExNywibmJmIjoxNjc4MzM2MTE3LCJleHAiOjE2NzgzNTQxMTcsImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc0lkIjoiMTI5OCIsInRva2VuX2FjY2Vzc05hbWUiOiJhc3NhYWJsb3ljY1VBVCIsInRva2VuX2FjY2Vzc1R5cGUiOiIyIn19.gvjN7R0rwUhJZ244-jFzn_KYVuhU0gIahtreylC4dyc", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.adminid + '","dept_id":"' + data + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			if (response.status == true) {
				this.agent_options = response.result.data;
				//selected the assigned user
				setTimeout(() => {
					this.select_agents = assigned_user;
					// alert(this.select_agents)
				}, 2000);

			}
		},
			(error) => {
				console.log(error);
			});
	}
	get_agents_by_department_noti(dept_ids, assigned_user) {
		var data = dept_ids;
		let access_token: any = localStorage.getItem('access_token');
		let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.adminid + '","dept_id":"' + data + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			if (response.status == true) {
				this.agent_options = response.result.data;
				//selected the assigned user
				setTimeout(() => {
					this.select_agents = assigned_user;
					this.assigned_user_perm = assigned_user;
					// alert(this.select_agents)
				}, 2000);

			}
		},
			(error) => {
				console.log(error);
			});
	}
	revokechat(chat_ids) {


		// revokeTransfer -> user_id & chat_id

		let access_token: any = localStorage.getItem('access_token');

		let api_req: any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"chatTransfer","chat_id":"' + chat_ids + '","user_id":"' + this.loginUser + '"}}';


		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.status == true) {
				iziToast.success({
					message: "Revoked successfully!",
					position: 'topRight'
				});
				this.chatPanelView("all");
			} else {
				iziToast.error({
					message: "Revoked successfully!",
					position: 'topRight'
				});
			}

		},
			(error) => {
				console.log(error);
			});
	}
	getAgentName(id) {

		let AgentName = this.agent_options.filter(t => t.user_id == id);
		console.log(AgentName)
		return AgentName[0].agent_name;
	}
	DecryptText(text) {
		// console.log(text)

		if (this.isBase64(text)) {
			return atob(text)
		} else
			return text;
	}
	isBase64(str) {
		if (str === '' || str.trim() === '') { console.log('0'); return false; }
		try {
			return btoa(atob(str)) == str;
		} catch (err) {
			// console.log('err');
			return false;
		}
	}
	getAllAgentsList() {
		let admin_id: any = localStorage.getItem('admin_id');
		let access_token: any = localStorage.getItem('access_token');
		//let api_req:any = '{"operation":"chatinternal", "moduleType":"chatinternal", "api_type": "web", "access_token":"'+access_token+'","element_data":{"action":"dept_agent_list","admin_id":"'+admin_id+'","user_id":"'+this.loginUser+'"}}';
		let api_req: any = '{"operation":"web_chat", "moduleType":"web_chat", "api_type": "web_chat", "access_token":"' + access_token + '","element_data":{"action":"get_users","admin_id":"' + admin_id + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.status == true) {
				console.log(response.result.data)
				// this.all_agent_list = response.result.data.agent_list;
				this.all_agent_list = response.result.data;

			} else {

			}
		},
			(error) => {
				console.log(error);
			});
	}
	MailtoAgent(id) {
		Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});
		let admin_id: any = localStorage.getItem('admin_id');
		let access_token: any = localStorage.getItem('access_token');
		let api_req: any = '{"operation":"chat", "moduleType":"chat", "api_type": "web", "access_token":"' + access_token + '","element_data":{"action":"mail_to_agents","chat_id":"' + this.chat_detail_key + '","widget_name":"' + btoa(this.widget_name) + '","user_id":"' + id + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			if (response.result.data == 1) {
				iziToast.success({
					message: "Mailed Successfully",
					position: "topRight"
				});
			} else {
				iziToast.error({
					message: "Failed to Mail",
					position: "topRight"
				})
			}
		},
			(error) => {
				console.log(error);
			});
	}
	getAgentByID(id) {
		//   alert(id)
		var data = this.all_agent_list.filter(t => t.user_id === id);
		return data[0].agent_name;
	}




	notifyChatPanel(chat_id, c_status) {

		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "chat_detail_list";
		chat_req.chat_type = "webchat";
		chat_req.chat_id = chat_id;
		chat_req.user_id = this.loginUser;
		api_req.operation = "chat";
		api_req.moduleType = "chat";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;

		this.serverService.sendServer(api_req).subscribe((response: any) => {
			Swal.close();
			if (response.result.status == 1) {

				if (c_status == '2') {
					this.chat_status_detail_id = 'closed';
					this.is_chat_closed = true;
				} else {
					this.is_chat_closed = false;
				}

				this.chat_panel_detail_type = "chat_detail";
				this.chat_panel_details = response.result.data.chat_detail_list;
				this.customer_name = response.result.data.chat_detail_list[0].customer_name;
				this.widget_name = response.result.data.chat_detail_list[0].widget_name;
				this.current_chat_id = response.result.data.chat_detail_list[0].chat_id;
				// var testdata = response.result.data.chat_detail_list[0].chat_msg;
				this.chat_department = response.result.data.chat_detail_list[0].department_name;
				this.chat_status_name = response.result.data.chat_detail_list[0].chat_status_name;




				// this.botlisting = testdata.split('||');
				if (response.result.data.chatbot_status == 0) {
					this.bot_status = true;
				}
				else {
					this.bot_status = false;
				}
				this.chatautoScroll();
				this.chat_detail_key = chat_id;
				this.chatPanelView2('all');
				//Transfer popup predefined values
				this.WidgetdeptList(response.result.data.chat_detail_list[0].department, response.result.data.chat_detail_list[0].assigned_user)

				//	alert(this.chat_detail_key)
				//To disable while closed
				var chat_origin = response.result.data.chat_detail_list[0].chat_status;
				if (chat_origin == 2 || chat_origin == 6)
					this.is_chat_closed = true;

				console.log(response.result.data.chat_detail_list[0].assigned_user.split(','))
				//Get Agent Name
				if (this.c_status != 1)
					this.chat_agent_name = this.getAgentByID(response.result.data.chat_detail_list[0].assigned_user)
			}

		},
			(error) => {
				console.log(error);
			});


	}





}
