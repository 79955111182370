 <div class="settingSidebar">
    <a href="javascript:void(0)" class="settingPanelToggle"> <i class="fa fa-spin fa-cog"></i>
    </a>
    <div class="settingSidebar-body ps-container ps-theme-default">
        <div class=" fade show active">
            <div class="setting-panel-header">Setting Panel
            </div>

              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Select Layout</h6>
                <div class="selectgroup layout-color w-50">
                  <label class="selectgroup-item">
                    <input type="radio" name="value" value="1" class="selectgroup-input select-layout" checked>
                    <span class="selectgroup-button">Light</span>
                  </label>
                  <label class="selectgroup-item">
                    <input type="radio" name="value" value="2" class="selectgroup-input select-layout">
                    <span class="selectgroup-button">Dark</span>
                  </label>
                </div>
              </div>

            <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Color Theme</h6>
                <div class="theme-setting-options">
                        <!-- <ul class="choose-theme list-unstyled mb-0">
                            <li title="white" class="active">
                                <div class="white"></div>
                            </li>
                            <li title="cyan">
                                <div class="cyan"></div>
                            </li>

                            <li title="purple">
                                <div class="purple"></div>
                            </li>

                            <li title="red">
                                <div class="red"></div>
                            </li>
                        </ul> -->

                        <ul class="choose-theme list-unstyled mb-0">
                            <li title="white" class="active">
                              <div class="white"></div>
                          </li>
                          <li title="cyan">
                              <div class="cyan"></div>
                          </li>
                          <li title="purple">
                              <div class="purple"></div>
                          </li>
                          <li title="orange">
                              <div class="orange"></div>
                          </li>
                          <li title="green">
                              <div class="green"></div>
                          </li>
                          <li title="black">
                              <div class="black"></div>
                          </li>
                          <li title="red">
                              <div class="red"></div>
                          </li>
                      </ul>

                  </div>
              </div>
              <div class="p-15 border-bottom">
                <div class="theme-setting-options">
                    <label>
                        <span class="control-label p-r-20">Mini Sidebar</span>
                        <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" id="mini_sidebar_setting">
                        <span class="custom-switch-indicator"></span>
                    </label>
                </div>
            </div>


        </div>
    </div>
</div>