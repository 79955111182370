
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header"> 
                    <h4>Aux Codes</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addDepartment()">+ Add Aux Code</button>
                    </div> &nbsp;&nbsp;
                  
                    <a (click)="showdoc('https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_auxcode.pdf')"  target="_blank"><i style="font-size: 24px;
                        float: right;" class="fa fa-question-circle"></i></a>
					<!-- <a style="margin-left: 20px;" href="https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_auxcode.pdf" target="_blank"><i style="font-size:24px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                     <table class="table table-bordered table-striped table-md">
                        <tr>
                            <th># </th>
                            <!-- <th>Aux ID</th> -->
                            <th>Aux Code</th>
                            <th>Action</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let queue of queue_list; let i=index">
                                <td>{{0+i+1}}</td>
                                <!-- <td>{{queue.id}}</td> -->
                                <td>{{queue.auxcode_name}}</td>
                            
                                <td>
                                    <span *ngIf="queue.id !='1' && queue.id !='2' && queue.id !='3' && queue.id !='4' && queue.id !='5' " class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editDepartmentSettings(queue.id)"></i></span>
                                    <span *ngIf="queue.id !='1' && queue.id !='2' && queue.id !='3' &&queue.id !='4' && queue.id !='5' " class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.id)"></i></span>
                                </td>
                            </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                 </table>
             </div>
         </div>

     </div>
 </div>
</div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editDepartment(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Aux Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Aux Code</label>
                                <input type="text" id="department_name" class="form-control" formControlName="department_name" >
                            </div>
                        </div>

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editDept.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Aux Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Aux Code</label>
                                <input type="text" id="department_name" class="form-control" formControlName="department_name" >
                            </div>
                        </div>



                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!addDept.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-md" id="document_model">
	<div class="modal-dialog modal-md"  style="max-width: 1200px;">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" style="color:crimson" >Help Manual</h5>
		  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="modal-body">
				  <!-- <iframe [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
				  <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
				<embed [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" />
		</div>     
		<div class="modal-footer" >
			<div class="buttons">
				<input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
			</div>
		</div>
  
	  </div>
	</div>
  </div>
