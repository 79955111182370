<div class="section-body">
    <div class="row">
        <div class="col-12 col-lg-10">
            <div class="card">
                <div class="card-header">
                    <h4>Global Settings</h4>
                    <div id="google_translate_element"></div>
                    
                    <input type="hidden" id="checkring" (click)="checkring()">
                    <input type="hidden" id="refresh_page" (click)="dept_settings()">
                </div>
                <div class="card-body p-30">
                    <div class="row">
                        
                        <div class="col-md-4">
                            

                            <div class="form-group">
                                <label for="timezone">Default TimeZone</label>
                                <select name="timezone"  id="timezone" class="form-control" >
                                    <option value='0'> Select TimeZone </option>
                                    <option *ngFor="let time of queue_list" value="{{time.id}}"  [selected]="time.id == this.myTime">{{time.name}}</option>
                                </select>
                            </div>


                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="timezone">Dialer Details</label>
                                <select name="dialer_det"  id="dialer_det" class="form-control" >
                                    <option value='2'> Internal  </option>
                                    <option value='1'> External </option>
                                </select>
                            </div>
                        </div>


                        <div class="col-md-2" *ngIf="show_admin_sett">
                                   
                                        <p>Show Caller Id</p>
                                        <div class="form-check form-check-inline">
                                            <div class="pretty p-switch p-fill">
                                                <input type="checkbox" id="show_caller_id" >
                                                <div class="state p-success">
                                                    <label for="has_sms"></label>
                                                </div>
                                            </div>
                                        </div>
                                    
                         </div> 
                                         <!-- <div class="col-md-2" *ngIf="show_admin_sett">
                                            
                                                        
                                                    <p>Video Dialer</p>
                                                    <div class="form-check form-check-inline">
                                                        <div class="pretty p-switch p-fill">
                                                            <input name="video_call" type="checkbox" id="has_video_call" >
                                                            <div class="state p-success">
                                                                <label for="video_call"></label>
                                                            </div>
                                                        </div>
                                                    </div>     
                                               
                                     </div>  -->

                        

                        <div class="col-md-4" *ngIf="show_admin_sett">
                            <div class="form-panel">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="contact_owner">Main Logo (250px * 68px)</label>
                                            <input type="file" class="form-control" name="logo_image" id="logo_image" size="150">
                                        </div>
                                        <img src="{{this.logo_image}}" width="250px">
                                    </div>

                                </div>
                            </div>
                        </div> 

                        <div class="col-md-4" *ngIf="show_admin_sett">
                            <div class="form-panel">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="contact_owner">Small Logo (65px * 65px)</label>
                                            <input type="file" class="form-control" name="small_logo_image" id="small_logo_image" size="150">
                                        </div>
                                        <img src="{{this.small_logo_image}}" width="100px">
                                    </div>

                                </div>
                            </div>
                        </div> 
                        <div class="col-md-4"  *ngIf="show_admin_sett">
                            <div class="form-panel">
                                <label for="Webrtc">Select WEBRTC Server</label>
                                <select  name="Webrtc"  id="webrtc_server" class="form-control" >
                                    <option value='0'> Select Webrtc </option>
                                    <option *ngFor="let time of server_list" value="{{time.server_id}}"  [selected]="time.server_id == this.server_ID">{{time.server_name}}</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-12">
                            <div class="form-panel">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label for="contact_owner">Custom Ringtone (Beep alert)</label>
                                            <div class="slidecontainer">
                                                <input type="range" min="1" max="4" value="{{dialer_ring_upt}}" (click)="setval()" class="slider" id="volume_step">
                                            
                                            </div>
                                            <label>{{this.valume}}</label>
                                            <audio id='incommingCalltone6' src='assets/images/incomingcall.mp3' ></audio>
                                            <audio id='incommingCalltone0' src=''></audio>
                                            <audio id='incommingCalltone1' src='assets/images/Low.mp3'></audio>
                                            <audio id='incommingCalltone2' src='assets/images/Medium.mp3' ></audio>
                                            <audio id='incommingCalltone3' src='assets/images/High.mp3'></audio>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <p>Default Ringer</p>
                                        <div class="form-check form-check-inline">
                                            <div class="pretty p-switch p-fill">
                                                <input name="ringer" type="checkbox" (click)="setval()" id="has_default_call" >
                                                <div class="state p-success">
                                                    <label for="ringer"></label>
                                                </div>
                                            </div>
                                        </div>     
                                   </div> 
                                </div>
                            </div>
                        </div>



                    </div>
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="submit" class="btn btn-success update_agent" value="Update Settings" (click)="updateGlobalSet()">
                                
                            </div>
                        </div>  </div>
                    </div>
                    
                </div>



                <div class="card" *ngIf="(show_admin_sett && this.h_con == 1)">
                    <div class="card-header">
                        <h4>CRM Settings</h4>
                        <input type="hidden" id="refresh_page" (click)="dept_settings()">
                    </div>
                    
                    <div class="card-body p-30">
                        <div class="row">
                            <div class="col-xl-3 col-md-6 col-12" >
                                <div class="form-group">
                                 <label for="display-options-default" class="pointer">
                                    <input id="display-options-default" type="radio" value="0"  name="has_external_contact" (change)="showCRMlink('default')">  Default
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 col-12" >
                            <div class="form-group">
                              <label for="display-options-crm" class="pointer">
                                <input type="radio" id="display-options-crm" value="1"  name="has_external_contact" (change)="showCRMlink('ext')">  Custom CRM
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 col-12" *ngIf="showurlsett">
                        <div class="form-group">
                            <label>External CRM</label>
                            <select name="crm_type" id="crm_type" class="form-control" (change)="crmType()">
                                <option value='0'>Select CRM</option>
                                <option value='FreshDesk'>FreshDesk</option>
                                <option value='HubSpot'>HubSpot</option>
                                <option value='Insightly'>Insightly</option>
                                <option value='Microsoft_Dynamics'>Microsoft Dynamics 365 CRM</option>
                                <option value='SalesForce'>SalesForce</option>
                                <option value='Shopify'>Shopify</option>
                                <option value='Vtiger'>Vtiger</option>
                                <option value='Zendesk'>Zendesk</option>
                                <option value='Zoho'>Zoho</option>
                                <option value='ZohoDesk'>ZohoDesk</option>
                            </select>
                        </div>         
                        <div class="form-group">
                            <label>External CRM URL</label>
                            <input type="text" class="form-control" value="{{this.external_contact_url}}" id="external_contact_url">
                        </div>
                        <div class="form-group">
                            <label>Behaviour URL</label>
                            <input type="text" class="form-control" value="{{this.behaviourURL}}">
                        </div>

                        <div class="form-group" *ngIf="showForHubSpot">
                            <p>Replace "Hubspot api Key" with your hubspot api key and add below url as External CRM URL </p>
                           <p>External CRM URL : https://{{this.host_name}}/#/edit-contacts?q=hubSpot&hapikey=Hubspot api Key</p> 
                         </div>
                        <div class="form-group" *ngIf="showForZDesk">
                           <a href="https://accounts.zoho.com/apiauthtoken/create?SCOPE=ZohoSupport/supportapi,ZohoSearch/SearchAPI" target="_blank">Click here to generate auth token and add below url as External CRM URL</a>
                          <p>External CRM URL : https://{{this.host_name}}/#/edit-contacts?q=zohoDesk&authkey=Auth Key&orgId=orgId</p> 
                        </div>

                        <div class="custom-logos fl" *ngIf="showurlsett">
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-freshdesk.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-hubspot.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-inslightly.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-md.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-zoho.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-shopify.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-vt.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-zendesk.png">
                                    </figure>
                                </div>
                                <div class="col-md-2 col-sm-4 col-12">
                                    <figure>
                                        <img src="assets/images/crm/logo-sf.png">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row" id="dailyfIframes">

                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <div class="settings-btn-group fl">
                            <div class="form-group">
                                <input type="button" class="btn btn-success update_agent" value="Update Settings" (click)="updateCRMSet()">
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div> 
  <!-- SSO  -->
            <div *ngIf="has_sso=='1'" class="card">
                <div class="card-header">
                    <h4>Microsoft SSO Settings</h4>
                    <div id="google_translate_element"></div>

                    <input type="hidden" id="checkring" (click)="checkring()">
                    <input type="hidden" id="refresh_page" (click)="dept_settings()">
                </div>
                <div class="card-body p-30">
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <h3>Create You Azure APP</h3>
                            <ul>
                                <li> Login to your Microsoft 365 account and go to "Azure Active Directory" > "Enterprise Application'"</li>
                                <!-- <li> Click on 'Enterprise Application' -> Create New App.</li> -->
                                <li> Click on 'Create own Application'.</li>
                                <li> Then it will ask for your 'App Name'.</li>
                                <li> Then Check on the third option and submit to create App.</li>
                            </ul>
                            <h4>Configure Single Sign on</h4>
                            <ul>
                                <li> Previous steps will get you to your Azure App.</li>
                                <li> Then you need to Click on 'Set up Single Sign on'.</li>
                                <li> Next Click on 'SAML'.</li>
                                <li> Then Edit you Basic SAML configuration.</li>                                                           
                            </ul>
                            <h4>Basic SAML configuration</h4>
                            <ul>
                                <li> Click 'EDIT' on Basic SAML configuration Block.</li>
                                <li> Enter your Entity ID manually.</li>
                                <li> Next Enter your Reply URL(Assertion Consumer Service URL) as <b>' {{SsoReplyURl}} '</b></li>
                                <li> Then Click 'Save'</li>                                                           
                            </ul>                              
                                <h6> Copy your App Entity ID and paste below</h6>
                            <div class="form-group">
                                <label>Identifier (Entity ID)</label>
                                <input type="text" class="form-control" id="sso_entity_id">
                            </div>
                            <div  class="form-group">
                                <label>Reply URL (Assertion Consumer Service URL)</label>
                                <input disabled type="text" class="form-control" value="{{SsoReplyURl}}" id="sso_reply_url">
                            </div>

                        
                            <h4>Azure AD Identifier</h4>
                            <ul>
                                <li> Assuming your in the Setup Sigle Sign on with SAML Pgae in your Azure APP.</li>
                                <li> Go to the 4th Step 'Set Up - APP'. </li>
                                <li> Please copy the 'Azure AD Identifier'.</li>
                                <li> Then Paste it in the below input field.</li>                                                           
                            </ul>  
                            <div class="form-group">
                                <label>Azure AD Identifier</label>
                                <input type="text" class="form-control" id="azure_ad_id">
                            </div>
                              <h4>Add Users to Azure APP</h4>
                              <ul>
                                <li> Go to your Azure App dashboard.</li>
                                <li> Click on the 'user Groups'. </li>
                                <li> Next click the 'Add users' button.</li>
                                <li> Select the users that you want and click update.</li>                                                           
                            </ul>   
                            <h4>Federation Metadata XML</h4>
                            <ul>
                                <li> Assuming your in the Setup Sigle Sign on with SAML Pgae in your Azure APP.</li>
                                <li> Go to the 3th Step 'SAML Signing Certificate'. </li>
                                <li> your Can See the 'Federation Metadata XML' at last row.</li>
                                <li> Then Download it.</li>                                                           
                            </ul>     
                            <div class="form-group">
                                <label>Federation Metadata XML</label>
                                <input type="file" class="form-control" id="federation_metadata_xml">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input *ngIf="activateSSObutton" type="submit" class="btn btn-success update_agent" value="Activate SSO"
                                    (click)="activateSSO()">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- SSO END -->
    </div>
</div> 
</div>