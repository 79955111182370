<div class="section-body">
    <div class="row">
        <div class="col-xl-8">
            <div class="card">
                <div class="card-header">
                    <h4>3CX Call Report</h4>

                </div>
                <div class="card-body p-30">
                    <form [formGroup]="getRep" (ngSubmit)="getReports()">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="agent_name">Select Agent</label>
                                    <select name="extension" multiple id="extension" class="form-control has-multiple-select" formControlName="extension">
                                        <option *ngFor="let user of usersL" value="{{user.sip_login}}">{{user.sip_login}} - {{user.agent_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="call_nature">Select Call Nature</label>
                                    <select name="call_nature" multiple id="call_nature" class="form-control has-multiple-select" formControlName="call_nature">
                                        <option *ngFor="let auxcode of call_nature" value="{{auxcode.call_nature}}">{{auxcode.call_nature}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="call_type">Select Call Type</label>
                                    <select name="call_type" multiple id="call_type" class="form-control has-multiple-select" formControlName="call_type">
                                        <option *ngFor="let auxcode of call_type" value="{{auxcode.call_type}}">{{auxcode.call_type}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="from_dt">From Date</label>
                                    <input type="date" id="from_dt" class="form-control" formControlName="from_dt">
                                </div>
                            </div>
                        </div>
                        <div class="row">


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="to_dt">To Date</label>
                                    <input type="date" id="to_dt" class="form-control" formControlName="to_dt">
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" (click)="getReports()">Generate Report</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            
        </div>
    </div> 
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Call History</h4>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                            <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" (keyup.enter)="callHistoryList({'search_text':call_history_search.value})" #call_history_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="callHistoryList({'search_text':call_history_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>#</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Call Nature</th>
                                <th>Call Type</th>
                                <th>Duration</th>
                                <th>Call time</th>
                                <th *ngIf="this.user_type == 2">Call Recording</th>
                            </tr>
                            <tr *ngFor="let history of call_history_list;let i=index" >
                                <td>{{offset_count+i+1}}</td>
                                <td>{{history.from_no}}</td>
                                <td>{{history.to_no}}</td>
                                <td><span class="callType"> <img src="assets/images/incoming-call.svg" *ngIf="history.call_nature == 'Inbound Call'"><img src="assets/images/outgoing-call.svg" *ngIf="history.call_nature == 'Outbound Call'"></span>{{history.call_nature}}</td>
                                <td>{{history.type}}</td>
                                <td>{{history.duration}}</td>
                                <td>
                                    {{history.dt_time}}
                                </td>
                                <td *ngIf="this.user_type == 2">
                                 <!--    <audio controls  *ngIf="history.rec_path != '' ">
                                        <source src="{{history.rec_path}}" type="audio/wav">
                                            <source src="{{history.rec_path}}" type="audio/mpeg">
                                                Your browser does not support the audio element.
                                            </audio> -->
                                            <div class="play-audio" *ngIf="history.rec_path != '' ">
                                                <a href="{{history.rec_path}}" target="_blank" download="rec.wav">
                                                <i class="fas fa-play-circle" ></i>
                                            </a>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr *ngIf="recordNotFound == true">
                                     <td colspan="12">Data not found</td>
                                 </tr>

                             </table>
                         </div>
                     </div>
                     <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                            <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? callHistoryList({'search_text':call_history_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="callHistoryList({'search_text':call_history_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? callHistoryList({'search_text':call_history_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="callHistoryList({'search_text':call_history_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? callHistoryList({'search_text':call_history_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



<!-- 

<div class="modal fade bd-example-modal-md" id="play-audio">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Audio Player</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-12">
                                            <audio controls>
  <source src="assets/3cx_audio.wav" type="audio/wav">
  </audio>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editDept.valid">
                    </div>
                </div>
        </div>
    </div>
</div> -->