
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>SMS Groups</h4>
                    <div class="addBtn">
                        
                        <a *ngIf="hide_admin_sett"  href="javascript:void(0)" class="btn btn-primary" routerLink="/sms-csv-upload" >Upload csv</a>

                        <button *ngIf="hide_admin_sett"  type="button" class="btn btn-primary" (click)="addGroup()">+ Add Group</button>
                    </div>
                    <a (click)="showdoc('https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_sms_groups.pdf')"  target="_blank"><i style="font-size: 24px;
                        float: right;" class="fa fa-question-circle"></i></a>
					<!-- <a href="https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_sms_groups.pdf" target="_blank"><i style="font-size:24px;padding-left: 20px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">

                            <tr>
                                <th>S.no </th>
                                <th>Group ID</th>
                                <th>Group Name</th>
                                <th>Action</th>
                            </tr>

                            <tbody>
                                <tr *ngFor="let queue of queue_list; let i=index">
                                    <td>{{0+i+1}}</td>
                                    <td>{{queue.group_id}}</td>
                                    <td>{{queue.group_name}}</td>
                                    <!-- <td>
                                        <button type="button" class="btn btn-primary" (click)="editGroupSettings(queue.dept_id)">Edit</button>
                                    </td> -->
                                    <td>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editGroupSettings(queue.group_id)"></i></span>
                                        <span *ngIf="hide_admin_sett" class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.group_id)"></i></span>
                                    </td>
                                </tr>

                                <tr *ngIf="recordNotFound == true">
                                 <td colspan="12">Data not found</td>
                             </tr>
                         </tbody>

                     </table>
                 </div>
             </div>

         </div>
     </div>
 </div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editGroup(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Group</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Group Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="group_name" class="form-control" formControlName="group_name" >
                            </div>
                        </div>
                    </div>
                        

                        <div class="row">
                            <div class="col-md-12">
                                <h6>Group Users</h6>
                            </div>
                        </div>

                        <div class="row has-row-space">
                            <div class="col-md-12">
                                <div class="checkbox-row" *ngFor="let agent of agents_list">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox" [attr.checked]="userchecked && userchecked.indexOf(agent.contact_id) == -1 ? null : 1"  class="ads_Checkbox" value="{{agent.contact_id}}" name="listusers[]" >
                                            <div class="state p-success">
                                                <label for="listusers">{{agent.first_name}}</label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <h6>Status</h6>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox-row">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox" id="status" class="form-control" formControlName="status" >
                                            <div class="state p-success">
                                                <label for="status">Status</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="buttons">
                            <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                            <input type="submit" class="btn btn-primary update_agent" value="Submit" [disabled]="!editDept.valid">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>






    <div class="modal fade bd-example-modal-md" id="add_deptform">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myLargeModalLabel">Add Group Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                      <div class="row">
                        <div class="col-md-12">
                            <h6>Group Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="group_name" class="form-control" formControlName="group_name" >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <h6>Group Users</h6>
                    </div>
                </div>

                <div class="row has-row-space">
                    <div class="col-md-12">
                        <div class="checkbox-row" *ngFor="let agent of agents_list">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" class="add_Checkbox" value="{{agent.contact_id}}" name="addlistusers[]">
                                    <div class="state p-success">
                                        <label for="listusers">{{agent.first_name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h6>Status</h6>
                    </div>

                    <div class="col-md-12">
                         <div class="checkbox-row">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="status" class="form-control" formControlName="status" >
                                    <div class="state p-success">
                                        <label for="status">Status</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary update_agent" value="Submit" [disabled]="!addDept.valid">
                </div>
            </div>
        </form>

    </div>
</div>
</div>

<div class="modal fade bd-example-modal-md" id="document_model">
	<div class="modal-dialog modal-md"  style="max-width: 1200px;">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" style="color:crimson" >Help Manual</h5>
		  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="modal-body">
				  <!-- <iframe [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
				  <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
				<embed [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" />
		</div>     
		<div class="modal-footer" >
			<div class="buttons">
				<input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
			</div>
		</div>
  
	  </div>
	</div>
  </div>