<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Queue List</h4>
                    <div class="addBtn btn-group">
                     <button type="button" class="btn btn-primary" (click)="addQueues()">+ Add Queue</button>
                        <button type="button" class="btn btn-primary" (click)="retriveFrom3cx()" *ngIf="this.hardware_id !=''">Retrieve From 3CX</button>
                        <input type="hidden" value="" id="datagetsucced" (click)="datagetsucced()">
                        <input type="hidden" value="" id="datagetsfailed" (click)="datagetsfailed()">

                    </div>
					<a style="margin-left: 20px;" href="https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_queue.pdf" target="_blank"><i style="font-size:24px;" class="fa fa-exclamation-circle"></i></a>

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                            <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" (keyup.enter)="queueList({'search_text':queue_search.value})" #queue_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="queueList({'search_text':queue_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>S.no</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>


                            <tr *ngFor="let queue of queue_list;let i=index">
                                <td>{{offset_count+i+1}}</td>
                                <td>{{queue.queue_name}}</td>
                                 <td>
                                    <div [class]="queue.queue_status == 1 ? 'badge badge-success' : queue.queue_status == 4 ? 'badge badge-warning' : 'badge badge-danger'">{{queue.status_name}} </div>                                
                                <td>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editQueueSettings(queue.queue_id)"></i></span>
                                    <span class="user-icon" style="cursor: pointer;"><i class="fa fa-eye"   (click)="viewQuePerformance(queue.queue_id)"></i></span>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.queue_id)"></i></span>
                                </td>
                            </tr>

                             <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                           
                        </table>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? queueList({'search_text':queue_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="queueList({'search_text':queue_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? queueList({'search_text':queue_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="queueList({'search_text':queue_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? queueList({'search_text':queue_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <input type="hidden" id="editinQueuser" (click)="editQueues(dep_id)">
            <input type="hidden" id="editnotinQueuser" >
            <form [formGroup]="editQueue" (ngSubmit)="getMainQueuesEdit()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Queue</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Queue Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="queue_name" class="form-control" formControlName="queue_name" >
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-12">
                            <h6>Queue Number</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="queue_number" class="form-control" formControlName="queue_number" >
                            </div>
                        </div>
                    </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="department_users">Select Queue Users</label>
                                <input type="text" id="department_users" class="form-control" formControlName="department_users">
                            </div>
                            
                        </div> -->

                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h6>Queue Users</h6>
                            </div>
                        </div> -->

                        <!-- <div class="row has-row-space">
                            <div class="col-md-12">
                                <div class="checkbox-row" *ngFor="let agent of agents_list">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox" id="has_wechat" [attr.checked]="userchecked && userchecked.indexOf(agent.sip_login) == -1 ? checked : ''"  class="ads_Checkbox" value="{{agent.user_id}}" name="listusers[]" >
                                            <div class="state p-success">
                                                <label for="listusers">{{agent.user_name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <h6>Status</h6>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox-row">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox" id="queue_status" class="form-control" formControlName="queue_status" >
                                            <div class="state p-success">
                                                <label for="queue_status">Status</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="buttons">
                            <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                            <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editQueue.valid">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>






    <div class="modal fade bd-example-modal-md" id="add_deptform">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <!-- <form [formGroup]="addQueue" (ngSubmit)="getMainQueues()"> -->
                    <form [formGroup]="addQueue" (ngSubmit)="AddQueue()">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Queue Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                      <div class="row">
                        <div class="col-md-12">
                            <h6>Queue Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="queue_name" class="form-control" formControlName="queue_name" >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Queue Number</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="queue_number" class="form-control" formControlName="queue_number" >
                            </div>
                        </div>
                    </div>


                    <div class="row">
                      <div class="col-md-12">
                        <h6>Queue Users</h6>
                    </div>
                </div>

                <div class="row has-row-space">
                    <div class="col-md-12">
                        <div class="checkbox-row" *ngFor="let agent of agents_list">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" class="add_Checkbox" value="{{agent.sip_login}}" name="addlistusers[]">
                                    <div class="state p-success">
                                        <label for="listusers">{{agent.user_name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h6>Status</h6>
                    </div>

                    <div class="col-md-12">
                         <div class="checkbox-row">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="queue_status" class="form-control" formControlName="queue_status" >
                                    <div class="state p-success">
                                        <label for="queue_status">Status</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary update_agent" value="Submit" >
                </div>
            </div>
        </form>

    </div>
</div>
</div>

<input type="hidden" id="addinQueuser" (click)="addQueueData()">
<input type="hidden" id="addnotinQueuser">


