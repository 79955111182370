import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Pipe } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
declare var iziToast: any;
declare var medi: any;

import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-contacts',
  templateUrl: './edit-contacts.component.html',
  styleUrls: ['./edit-contacts.component.css']
})

export class EditContactsComponent implements OnInit {

  incomecallNum;
  getaccountid;
  acc_name;
  param1: string;
  param3;
  created_time;
  modified_time;
  contact_id;
  addRefined: FormGroup;
  departments;
  uadmin_id;
  auxcodes;
  fromEdit;
  questions;
  to_num;
  call_record_id;
  admin_id;
  dailyfood = false;
  alladmin = true;
  refineshow = false;
  dsk_access;
  hasdsk = false;
  show_failed = false;
  hasnodsk = true;
  external_contact_url;
  public allowMp = true;
  public startrec = false;
  dailyfoodurl;
  has_external_contact;
  IFlink;
  show_caller_id;
  hide_cal1er = '0';
  click_to_call_number;
  click_to_call_mobile_number;
  click_to_call_office_phone;
  click_to_call_home_phone;
  crm_type;
  hapikey;
  paramq;
  dailyfoodurlhub;
  z_authId;
  z_orgId;
  paramCall;
  popupnumber;
  MDy_bearer_token;
  contact_name;
  phone_activity;
  websocket;
  extension;
  has_hard_id;
  recordNotFound;
  single_timeline;
  Tsubject;
  Tdescription;
  Tphone;
  Trecord;
  Twrapcode;
  Twrapnote;
  conc_query_list;
  user_type;
  queue_values;
  listsuggestion: any;
  paginationdata: any;
  no_contact = false;
  showloading = false;
  auxcode_catagory;
  start_list;
  end_list;
  category_name;
  get_dailer_value;
  show_mini_butons = true;
  show_ans_del = false;
  show_ans = false;
  show_del = false;
  show_contact_value = false;
  showphoneloader = false;
  showdatalist = false;
  Queue_number;
  admin_permission;
  constructor(private serverService: ServerService, private router: Router, private route: ActivatedRoute, public modalService: NgbModal) {


    this.addRefined = new FormGroup({
      'add_group_name': new FormControl(null, Validators.required),
    });

    this.param1 = this.route.snapshot.queryParamMap.get('phone');
    this.param3 = this.route.snapshot.queryParamMap.get('ids');
    this.paramCall = this.route.snapshot.queryParamMap.get('calltype');

    var decodedString = atob(this.param1);
    this.param1 = decodedString;
    this.get_dailer_value = localStorage.getItem("income_calls_num");
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.serverService.editContact.subscribe((val: any) => {

      var dpContent = JSON.parse(val);
      // console.log(dpContent);
      if (dpContent.type == "show_popup") {
        this.param1 = dpContent.caller_no;
        this.paramCall = dpContent.call_type;
        // this.queue_values = dpContent.queue_num;
        if (dpContent.show_buttons == "true") {
          // this.show_mini_butons = true;
          this.show_ans = true;
          this.show_del = true;
        } else {
          // this.show_mini_butons = false;
          this.show_ans = false;
          this.show_del = false;
        }
        console.log(this.param1);
        //  this.editConatcts();        
      }
      if (dpContent.type == "call_ended") {
          // alert('called')
        this.show_ans = false;
        this.show_del = false;
        localStorage.removeItem('ans_click');
      }
      if (dpContent.type == "call_info") {
        // alert('in')
        var test = localStorage.getItem('ans_click');
       
          if(test == null){
          this.show_ans = false;
          this.show_del = false;          
          }else if(test == '1'){
            this.show_del = true;
          }else{
          this.show_ans = true;
          this.show_del = true;
          }
          // alert(test)
        this.param1 = dpContent.caller_no;
        this.paramCall = dpContent.call_type;
    
   
      }
    });

  }

  ngOnChanges() {

  }



  ngOnInit() {

    this.uadmin_id = localStorage.getItem('userId');
    this.show_caller_id = localStorage.getItem('show_caller_id');
    this.extension = localStorage.getItem('ext_num');
    this.has_hard_id = localStorage.getItem('hardware_id');
    // let user_id: any=localStorage.getItem('userId');

    $('#user_number').val(this.extension);
    //  this.getDepartments();
    //  this.getAuxCode();
    // if (this.param3 != '' && this.param3 != null && this.param3 != undefined) {
    //   if (this.get_dailer_value != 'unknown' && this.get_dailer_value != 'anonymous') {
    //     this.editbutttonContacts();
    //   } else {
    //     $('#first_name').val('');
    //     $('#last_name').val('');
    //     $('#job_title').val('');
    //     $('#acc_name').val('');
    //     $('#ax_name').val('');
    //     $('#account_ids').val('');
    //     this.no_contact=true;
    //     Swal.fire({
    //       title: 'Contact Unknown',
    //       text: "You can save contact by hitting the Add contact button",
    //       icon: 'info',
    //       showCancelButton: false,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'Got it'
    //     })
    //   }
    // } else {
      if (this.get_dailer_value != 'unknown' && this.get_dailer_value != 'anonymous') {
        this.editConatcts();
      } else {
        $('#first_name').val('');
        $('#last_name').val('');
        $('#job_title').val('');
        $('#acc_name').val('');
        $('#ax_name').val('');
        $('#account_ids').val('');       
        this.no_contact=true;
        Swal.fire({
          title: 'Contact Unknown',
          text: "You can save contact by hitting the Add contact button",
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Got it'
        })

      }
    // }
    this.initSocket();
    // if(this.fromEdit){

    // } else {
    //   this.getquestionnaire();
    // }
    this.toggleClass();
    this.admin_id = localStorage.getItem('admin_id');
    this.dsk_access = localStorage.getItem('dsk_access');
    this.has_external_contact = localStorage.getItem('has_external_contact');
    this.user_type = localStorage.getItem('user_type');
    this.admin_permission = localStorage.getItem('admin_permision');
	

		if (this.user_type == 'Super Admin') {
			this.user_type = 1;
		}
		else if (this.user_type == 'Admin' || this.admin_permission =='1') {
			this.user_type = 2;	 
		}
		else {
			this.user_type = 3;		 
		}
    if ($("body").hasClass("sidebar-mini")) {
      $("body").removeClass("sidebar-mini");
      $("body").addClass("sidebar-mini");
    } else {
      $("body").addClass("sidebar-mini");
    }

  }

  ngAfterViewInit() {
    // if(this.crm_type == 'SalesForce' ){
    //   this.dailyfoodurl = this.external_contact_url;
    // } else if(this.crm_type == 'HubSpot'){

    // }


    if (this.crm_type == 'SalesForce') {
      this.dailyfoodurl = this.external_contact_url;
      $('<iframe is="x-frame-bypass" class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurl).appendTo('#dailyfIframes');

    } else if (this.crm_type == 'HubSpot') {
      this.dailyfoodurl = this.external_contact_url;
      let access_token: any = localStorage.getItem('access_token');

      if (this.paramq == '') {
        this.dailyfoodurl = this.external_contact_url;
      } else {
        this.dailyfoodurl = 'https://api.hubapi.com/contacts/v1/search/query?q=' + this.paramq + '&hapikey=' + this.hapikey;
      }


      let api_req: any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"curl_response","url":"' + this.dailyfoodurl + '"}}';

      this.serverService.sendServer(api_req).subscribe((response: any) => {
        //  console.log(response.contacts[0]);
        this.dailyfoodurlhub = response.contacts[0]['profile-url'];
        // alert(this.dailyfoodurlhub);
        $('<iframe  class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurlhub).appendTo('#dailyfIframes');
      },
        (error) => {
          console.log(error);
        });
    } else if (this.crm_type == 'ZohoDesk') {
      this.dailyfoodurl = this.external_contact_url;
      let access_token: any = localStorage.getItem('access_token');
      this.z_authId = this.route.snapshot.queryParamMap.get('authkey');
      this.z_orgId = this.route.snapshot.queryParamMap.get('orgId');
      var number = this.route.snapshot.queryParamMap.get('q');


      if (this.paramq == '') {
        this.dailyfoodurl = this.external_contact_url;
      } else {
        this.dailyfoodurl = 'https://desk.zoho.com/api/v1/search?module=contacts&searchStr=' + this.paramq;
      }


      let api_req: any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"curl_response_zoho_desk","url":"' + this.dailyfoodurl + '","authkey":"' + this.z_authId + '","orgID":"' + this.z_orgId + '","number":"' + number + '"}}';

      this.serverService.sendServer(api_req).subscribe((response: any) => {
        this.dailyfoodurlhub = response.result.data;
        // alert(this.dailyfoodurlhub);
        $('<iframe is="x-frame-bypass" class="dailyfIfram" width="100%" height="800px" frameborder="0" allowfullscreen ></iframe>').attr('src', this.dailyfoodurlhub).appendTo('#dailyfIframes');
      },
        (error) => {
          console.log(error);
        });
    }


  }
  tesr() {
    medi();
    this.allowMp = false;
    $("#btns").css("display", "block");
  }

  openRefinement() {
    $("#add_refinement").modal('show');
  }

  initSocket() {
var self=this;
    if (this.admin_id == '66') {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002");
    } else if (this.admin_id == '201') {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003");
    } else {
      // this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4014"); // PROD
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4014"); // UAT
    }


    this.websocket.onopen = function (event) {
      console.log('Dialpad socket connected');
    }

    this.websocket.onmessage = function (event) {
      // console.log(event.data);
      var result_message = JSON.parse(event.data);
      //    console.log(result_message);  
      //    console.log($('#user_number').val());
      this.has_hard_id = localStorage.getItem('hardware_id');
      if (result_message[0].cust_id == this.has_hard_id) {
        // console.log('matched');
      } else {
        // console.log('not matched');
        return false;
      }

      if (result_message[0].data[0].wrapuptype == "wrapupcall_id" && result_message[0].data[0].extno == $('#user_number').val()) {
        $('#wrapup_callID').val(result_message[0].data[0].callid);
        // alert(result_message[0].data[0].callid)
        // alert('asnja')
        // $('#wrapup_callID').click();
      }
      if (result_message[0].data[0].calltype == "Incoming Call" && result_message[0].data[0].ag_no == $('#user_number').val()) {
        $('#queue_ids2').val(result_message[0].data[0].q_no);
      //   self.Queue_number=result_message[0].data[0].q_no;
      //   // alert(result_message[0].data[0].q_no)
      //   iziToast.info({
      //     title:""+result_message[0].data[0].q_no+"",
      //     message: "Queue No is '"+result_message[0].data[0].q_no+"'",
      //     position: 'topRight',                
      //     timeout: 1000,
      // });
      }


    }

    this.websocket.onerror = function (event) {
      console.log('error');
    }
    this.websocket.onclose = function (event) {
      console.log('close');
      $('#reconnect_socket').click();

    }




  }
  getquestionnaire() {
    if (this.questions != null)
      return false;

    let access_token: any = localStorage.getItem('access_token');
    let admin_id: any = localStorage.getItem('admin_id');
    let api_req: any = '{"operation":"questionaire", "moduleType":"questionaire", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"get_user_queue","user_id":"' + admin_id + '"}}';

    // this.serverService.sendServer(api_req).subscribe((response:any) => {
    //   if(response.status==true){
    //     // $('.settingSidebar').addClass('showSettingPanel');
    //     this.questions= response.result.data;
    //   } else {
    //   }
    // }, 
    // (error)=>{
    //     console.log(error);
    // });
  }

  closeQuestion() {
    $('.settingSidebar').removeClass('showSettingPanel');
  }


  toggleClass() {
    // this.getAuxCode();
    this.getAuxCatogory();
    if (this.fromEdit) {

    } else {
      this.getquestionnaire();
    }

    $('.settingSidebar').toggleClass('showSettingPanel');
  }




  getDepartments() {
    if (this.departments != null)
      return false;

    let access_token: any = localStorage.getItem('access_token');
    let admin_id: any = localStorage.getItem('admin_id');
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"get_dept_settings","user_id":"' + admin_id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.status == true) {
        this.departments = response.result.data;
      } else {
      }
    },
      (error) => {
        console.log(error);
      });
  }
  getAuxCatogory() {
console.log(this.auxcode_catagory)
    // if (this.auxcode_catagory != null)
    //   return false;

    let access_token: any = localStorage.getItem('access_token');
    let admin_id: any = localStorage.getItem('admin_id');
    let api_req: any = '{"operation":"getAuxcode", "moduleType":"contact", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"get_aux_code_category","admin_id":"' + admin_id + '","user_id":"' + this.uadmin_id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.status == true) {
        this.auxcode_catagory = response.result.data;
      } else {
      }
    },
      (error) => {
        console.log(error);
      });
  }

  getAuxCode() {
    // if(this.auxcodes!=null)
    //  return false;
    let cat_id = $('#auxcodes_pop').val();
    if(cat_id==0)
    return false;

    this.getCatname(cat_id);
    let access_token: any = localStorage.getItem('access_token');

    let api_req: any = '{"operation":"getAuxcode", "moduleType":"contact", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"getuax_by_cat","cat_id":"' + cat_id + '","admin_id":"' + this.admin_id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.status == true) {
        this.auxcodes = response.result.data;
      } else {
      }
    },
      (error) => {
        console.log(error);
      });
  }


  getCatname(id) {
    let access_token: any = localStorage.getItem('access_token');
    let api_req: any = '{"operation":"getAuxcode_data", "moduleType": "contact", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"edit_aux_code_category","cat_id":"' + id + '","admin_id":"' + this.admin_id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.status == true) {
        var agent_data = response.result.data;
        this.category_name = agent_data.category_name;

      } else {
        iziToast.warning({
          message: "Wrap Up codes not retrive. Please try again",
          position: 'topRight'
        });

      }
    },
      (error) => {
        console.log(error);
      });
  }

  autosuggestAccName() {

    let acc_name = $("#account_name").val();

    if (acc_name.length >= 5) {
      this.showloading = true;
      let api_req_get_access: any = new Object();
      api_req_get_access.apiFor = "getAutoAccName";

      acc_name=acc_name.replace(/ /g, '%20');
      api_req_get_access.acc_name = acc_name;
      api_req_get_access.accessToken = this.MDy_bearer_token;


      this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {

        this.paginationdata = response.id; 
        this.start_list = 0;
        this.end_list = 10; 
        this.pagedropdown(this.start_list,this.end_list)
        this.showdatalist = true;
        this.showloading = false;
      },
        (error) => {
          console.log(error);
        });

    }
  }

pagedropdown(start,end){
  console.log(start);
  console.log(end);
  console.log(this.paginationdata);
  var i, j, chunk = 10;
  for (i = start, j = end; i < j; i += chunk) {
    this.listsuggestion = this.paginationdata.slice(i, i + chunk);
    console.log(this.listsuggestion);
    // do whatever
  }
}

 nextPage(){
  this.start_list = this.start_list+10;
  this.end_list = this.end_list+10; 
  console.log(this.start_list);
  console.log(this.end_list);
  
  this.pagedropdown(this.start_list,this.end_list);
}

prevPage(){
  this.start_list = this.start_list-10;
  this.end_list = this.end_list-10; 
  console.log(this.start_list);
  console.log(this.end_list);

  this.pagedropdown(this.start_list,this.end_list);
}

  sentDataInInput(selectname){
    $("#account_name").val(selectname);
    this.showdatalist = false;
    this.searchnumAccount();
  }


  searchnumAccount() {

    console.log($("#account_name").val());

    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',


    });
    let api_req_get_contact: any = new Object();
    let api_req_get_access: any = new Object();
    let api_req_get_acc_contact: any = new Object();
    api_req_get_access.apiFor = "getAcesstoken";


    this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {
      this.MDy_bearer_token = response.access_token;


      api_req_get_contact.apiFor = "getAccountID";

      let acc_name = $("#account_name").val();
      api_req_get_contact.acc_name = acc_name;
      api_req_get_contact.accessToken = this.MDy_bearer_token;

      this.serverService.MDy_Contacts_API(api_req_get_contact).subscribe((response: any) => {
        let acc_ids = response.id;

        Swal.close();
        api_req_get_acc_contact.apiFor = "getAccountPhone";
        api_req_get_acc_contact.acc_ids = acc_ids;
        api_req_get_acc_contact.accessToken = this.MDy_bearer_token;

        this.serverService.MDy_Contacts_API(api_req_get_acc_contact).subscribe((response: any) => {
          console.log(response);

          this.conc_query_list = response.id;
          if (response.id != null && response.id != []) {
            this.show_contact_value = true;
            this.show_failed = false;
            this.no_contact = false;
          } else {
            this.show_failed = true;
            this.show_contact_value = false;
            this.no_contact = true;
          }

        },
          (error) => {
            console.log(error);
          });



      },
        (error) => {
          console.log(error);
        });





    },
      (error) => {
        console.log(error);
      });


  }

  cancelbutton() {
    this.show_contact_value = false;
    $("#add_refinement").modal('hide');
  }


  SearchContacts() {
    // alert(this.paramCall);
    if($("#business_phone").val() == this.incomecallNum){
      iziToast.warning({
        message:"No changes found in business Phone",
        position:"topRight"
      })
      return false
    }
    if($("#business_phone").val() == ''||$("#business_phone").val() == null ||$("#business_phone").val() == undefined){
      iziToast.warning({
        message:"Please Enter the Business mobile number",
        position:"topRight"
      })
      return false
    }
    this.showphoneloader = true;
    let conct_req: any = new Object();
    let api_req_get_access: any = new Object();
    let api_req_get_contact: any = new Object();
    let api_req_get_activity: any = new Object();



    // alert(this.paramq);
    api_req_get_access.apiFor = "getAcesstoken";


    this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {
      this.MDy_bearer_token = response.access_token;
      api_req_get_contact.apiFor = "getContact_omni";

      var param1 = $("#business_phone").val();
     
      api_req_get_contact.number = param1;
      api_req_get_contact.accessToken = this.MDy_bearer_token;

      this.serverService.MDy_Contacts_API(api_req_get_contact).subscribe((response: any) => {
        this.showphoneloader = false;
        // if (response.data != "") {
          if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
            let wrapcall_id = $('#wrapup_callID').val();
         
            var socket_message = '[{"cust_id":"' + this.has_hard_id + '","data":[{"Name":"unknowncontact","callid":"' + wrapcall_id + '","contactno":"' + response.data[0].telephone1 + '","contact_id":"' + response.data[0].contactid + '"}]}]';
            this.websocket.send(socket_message);
          let value = response.data[0];
          $('#first_name').val(value.firstname);
          $('#last_name').val(value.lastname);
          $('#job_title').val(value.jobtitle);
          // $('#account_name').val(value.fullname);
          $('#email').val(value.emailaddress1);
          $('#business_phone').val(value.telephone1);
          this.incomecallNum = value.telephone1;
          this.getaccountid = value._parentcustomerid_value;

          this.getAccountName(this.getaccountid);
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val(value.mobilephone);
          $('#fax').val(value.fax);
          this.contact_name = value.fullname;

          api_req_get_activity.apiFor = "getPhoneCallActivity";
          api_req_get_activity.number = this.incomecallNum;
          api_req_get_activity.accessToken = this.MDy_bearer_token;
          this.serverService.MDy_Contacts_API(api_req_get_activity).subscribe((response: any) => {

            // if(response.status==true){
              if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
              this.phone_activity = response.data;
              //  console.log(this.phone_activity)
              //  this.phone_activity=this.phone_activity.reverse();
              //  console.log(this.phone_activity)

              this.recordNotFound = false;

            } else {
              this.recordNotFound = true;
              this.phone_activity = [];
            }


          },
            (error) => {
              console.log(error);
            });

            this.refineshow = false;
        } else {
          this.no_contact = true;
          this.refineshow = true;

          $('#first_name').val('');
          $('#last_name').val('');
          $('#job_title').val('');
          $('#acc_name').val('');
          $('#ax_name').val('');
          $('#account_ids').val('');
          // $('#account_name').val();
          $('#email').val('');
          $('#business_phone').val(this.param1);
          this.incomecallNum = this.param1;
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val('');
          $('#fax').val();
          this.contact_name = "Contact Details";
          Swal.fire({
            title: 'Contact Not Found',
            text: "You can save contact by hitting the Add contact button",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Got it'
          })
          // alert('aas')
          // this.phone_activity = '';
          this.phone_activity =[];
        }

      },
        (error) => {
          console.log(error);
        });


    });
  }


  searchGetContact() {

    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',


    });
   var param1 = $("#add_acc_num").val();
    if ( param1 != '0') {    
      this.param3=btoa(param1);    
      this.editbutttonContacts();
    }else{
      iziToast.warning({
        message:"We are having trouble to get contact ID",
        position:"topRight"
      });
    }
  }

  editConatcts() {
    // alert(this.paramCall);
    // alert(this.param1);
    let conct_req: any = new Object();
    let api_req_get_access: any = new Object();
    let api_req_get_contact: any = new Object();
    let api_req_get_activity: any = new Object();



    // alert(this.paramq);
    api_req_get_access.apiFor = "getAcesstoken";


    this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {
      this.MDy_bearer_token = response.access_token;
      api_req_get_contact.apiFor = "getContact_omni";


      api_req_get_contact.number = this.param1;
      api_req_get_contact.accessToken = this.MDy_bearer_token;

      this.serverService.MDy_Contacts_API(api_req_get_contact).subscribe((response: any) => {
        Swal.close();
        $('#add_refinement').modal('hide');


        if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
          let value = response.data[0];
          $('#first_name').val(value.firstname);
          $('#last_name').val(value.lastname);
          $('#job_title').val(value.jobtitle);
          $('#account_ids').val(value._parentcustomerid_value);
          // $('#account_name').val(value.fullname);
          $('#email').val(value.emailaddress1);
          $('#business_phone').val(value.telephone1);
          this.incomecallNum = value.telephone1;
          this.getaccountid = value._parentcustomerid_value;
          this.getAccountName(this.getaccountid);
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val(value.mobilephone);
          $('#fax').val(value.fax);
          this.contact_name = value.fullname;


          api_req_get_activity.apiFor = "getPhoneCallActivity";
          api_req_get_activity.number = this.incomecallNum;
          api_req_get_activity.accessToken = this.MDy_bearer_token;
          this.serverService.MDy_Contacts_API(api_req_get_activity).subscribe((response: any) => {

            // if(response.status==true){
            // if (response.data != "") {
              if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
              this.phone_activity = response.data;
              //  console.log(this.phone_activity)
              //  this.phone_activity=this.phone_activity.reverse();
              //  console.log(this.phone_activity)

              this.recordNotFound = false;

            } else {
              this.recordNotFound = true;
              this.phone_activity = [];
            }


          },
            (error) => {
              console.log(error);
            });

            this.refineshow = false;
        } else {
          this.no_contact = true;
          this.refineshow = true;
          $('#first_name').val('');
          $('#last_name').val('');
          $('#job_title').val('');
          $('#acc_name').val('');
          $('#ax_name').val('');
          $('#account_ids').val('');
          // $('#account_name').val();
          $('#email').val('');
          $('#business_phone').val(this.param1);
          this.incomecallNum = this.param1;
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val('');
          $('#fax').val();
          this.contact_name = "Contact Details";


          Swal.fire({
            title: 'Contact Not Found',
            text: "You can save contact by hitting the Add contact button",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Got it'
          })
          this.phone_activity = [];
        }

      },
        (error) => {
          console.log(error);
        });


    });
  }

  editbutttonContacts() {
    // alert(this.paramCall);
    // alert(this.param1);
    let conct_req: any = new Object();
    let api_req_get_access: any = new Object();
    let api_req_get_contact: any = new Object();
    let api_req_get_activity: any = new Object();



  //  alert(this.param3);
    api_req_get_access.apiFor = "getAcesstoken";


    this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {
      this.MDy_bearer_token = response.access_token;
      api_req_get_contact.apiFor = "getContactbyId";
      api_req_get_contact.numberid = atob(this.param3);
      api_req_get_contact.accessToken = this.MDy_bearer_token;

      this.serverService.MDy_Contacts_API(api_req_get_contact).subscribe((response: any) => {
        Swal.close();
        $('#add_refinement').modal('hide');
        this.show_contact_value=false;
        $('#account_name').val('');

        // if (response.data != "") {
          if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
// Send to Mrvoip for new contact to add wrapupcode
            let wrapcall_id = $('#wrapup_callID').val();
            
            var socket_message = '[{"cust_id":"' + this.has_hard_id + '","data":[{"Name":"unknowncontact","callid":"' + wrapcall_id + '","contactno":"' + response.data.telephone1 + '","contact_id":"' + atob(this.param3) + '"}]}]';
            this.websocket.send(socket_message);

          let value = response.data;
          $('#first_name').val(value.firstname);
          $('#last_name').val(value.lastname);
          $('#job_title').val(value.jobtitle);
          // $('#account_name').val(value.fullname);
          $('#email').val(value.emailaddress1);
          $('#business_phone').val(value.telephone1);
          this.incomecallNum = value.telephone1;

// moved getPhoneCallActivity from after else bcz it will call only contact exists 
          this.getaccountid = value._parentcustomerid_value;
          this.getAccountName(this.getaccountid);
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val(value.mobilephone);
          $('#fax').val(value.fax);
          this.contact_name = value.fullname;
          api_req_get_activity.apiFor = "getPhoneCallActivity";
          api_req_get_activity.number = this.incomecallNum;
          api_req_get_activity.accessToken = this.MDy_bearer_token;
          this.serverService.MDy_Contacts_API(api_req_get_activity).subscribe((response: any) => {
    
              if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
              this.phone_activity = response.data;           
    
              this.recordNotFound = false;
    
            } else {
              this.recordNotFound = true;
              this.phone_activity = [];
            }
    
    
          },
            (error) => {
              console.log(error);
            });


        } else {
          this.no_contact = true;
          this.refineshow = true;
          Swal.fire({
            title: 'Contact Not Found',
            text: "You can save contact by hitting the Add contact button",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Got it'
          })
          $('#first_name').val();
          $('#last_name').val();
          $('#job_title').val();
          $('#acc_name').val();
          $('#ax_name').val();
          $('#account_ids').val('');
          $('#account_name').val('');
          $('#email').val();
          $('#business_phone').val(this.param1);
          this.incomecallNum = this.param1;
          // localStorage.setItem('income_calls_num', this.incomecallNum);
          $('#mobile_phone').val('');
          $('#fax').val();
          this.contact_name = "Contact Details";
          this.phone_activity =[];
          return false;
        }
      },
        (error) => {
          console.log(error);
        });     
    });
  }



  getAccountName(acc_ids) {

    let api_post_account: any = new Object();
    let api_ax_account: any = new Object();
    $('#account_ids').val(acc_ids);
    api_post_account.apiFor = "getAccountName";
    api_post_account.account_id = acc_ids;
    api_post_account.accessToken = this.MDy_bearer_token;
    this.serverService.MDy_Contacts_API(api_post_account).subscribe((response: any) => {
      console.log(response);
      if (response.acc_name != "") {
        //this.acc_name = response.acc_name;
        $("#acc_name").val(response.acc_name);
      }
      api_ax_account.apiFor = "getaxAccountId";
      api_ax_account.acc_name = response.acc_name;
      api_ax_account.accessToken = this.MDy_bearer_token;
      this.serverService.MDy_Contacts_API(api_ax_account).subscribe((response: any) => {

        if (response.accountnumber != "") {
          //this.acc_name = response.acc_name;
          $("#ax_name").val(response.acc_name[0].accountnumber);
        }
      },
        (error) => {
          console.log(error);
        });

    },
      (error) => {
        console.log(error);
      });

  }



  AddContact() {
    let api_post_contact: any = new Object();
    let first_name = $('#first_name').val();
    let last_name = $('#last_name').val();
    let job_title = $('#job_title').val();
    // $('#account_name').val();
    let email = $('#email').val();
    let business_phone = $('#business_phone').val();
    let mobile = $('#mobile_phone').val();
    let fax = $('#fax').val();

    if (first_name == "") {
      iziToast.warning({
        message: "Please enter the First Name",
        position: "topRight"
      });
      return false;
    }
    if (last_name == "") {
      iziToast.warning({
        message: "Please enter the Last Name",
        position: "topRight"
      });
      return false;
    }
    // alert(typeof business_phone);
    // if (typeof business_phone == "string") {
    //   iziToast.warning({
    //     message: "Invalid Business Number",
    //     position: "topRight"
    //   });
    //   return false;
    // }

    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',
    });
    let api_post_account: any = new Object();

    api_post_account.apiFor = "getOwnerID";
    api_post_account.ext_no = localStorage.getItem('ext_num');
    api_post_account.accessToken = this.MDy_bearer_token;
    this.serverService.MDy_Contacts_API(api_post_account).subscribe((response: any) => {

      let owner_id = response.id;
      if (response.id == "" || response.id == "null"|| response.id == null){
        Swal.close();
  iziToast.warning({
    message: "Sorry, We having trouble to get your OwnerID",
    position: "topRight"
  });
  return false
}
if (business_phone == "" || business_phone == "null"|| business_phone == null){
  Swal.close();
iziToast.warning({
message: "Sorry, Please Enter the Business Phone",
position: "topRight"
});
return false
}
      api_post_contact.apiFor = "AddNewContact";
      api_post_contact.accessToken = this.MDy_bearer_token;
      api_post_contact.Num = business_phone;
      api_post_contact.F_Name = first_name;
      api_post_contact.L_Name = last_name;
      api_post_contact.job_title = job_title;
      api_post_contact.email = email;
      api_post_contact.mobile = mobile;
      api_post_contact.fax = fax;
      api_post_contact.ownerid = owner_id;
      this.serverService.MDy_Contacts_API(api_post_contact).subscribe((response: any) => {
        Swal.close();
        let wrapcall_id = $('#wrapup_callID').val();

        
        // [{"cust_id":"8C05-99D2-0563-BF38-6561-6DB1-8501-CC0D","data":[{"Name":"unknowncontact","callid":"148","contactno":"0968738645"}]}]

        if (response.id != "" && response.id != "null"&& response.id != null) {
          iziToast.success({
            message: "Contact details Added to Microsoft Dynamics",
            position: "topRight"
          });
        
        var socket_message = '[{"cust_id":"' + this.has_hard_id + '","data":[{"Name":"unknowncontact","callid":"' + wrapcall_id + '","contactno":"' + business_phone + '","contact_id":"' + response.id + '"}]}]';
        this.websocket.send(socket_message);
          this.no_contact = false;
          this.editConatcts();
        } else {
          iziToast.error({
            message: "Sorry,Server issue ouccred",
            position: "topRight"
          })
          this.recordNotFound = true;
        }


      },
        (error) => {
          console.log(error);
        });

    },
      (error) => {
        console.log(error);
      });
  }



  clictToCall() {
    // if(to == 'phone'){  this.to_num = $('#phone').val(); } else {  this.to_num = $('#mobile').val(); }

    // if(to == 'phone'){  this.to_num = this.click_to_call_number; } else {  this.to_num = this.click_to_call_mobile_number; }
    let to = $('#business_phone').val();
    if (to == '') {
      iziToast.warning({
        message: "No Number To Call",
        position: 'topRight'
      });
    } else {


      let access_token: any = localStorage.getItem('access_token');

      var extention = localStorage.getItem('ext_int_status');
      //alert(extention);
      if (extention == '2') {
        let api_reqs: any = '{"type": "makecall", "number": "' + to + '","show_caller_id":"' + this.show_caller_id + '"}';
        this.serverService.show.next(api_reqs);
      } else {
        let api_reqs: any = '{"type": "makecallauto", "number": "' + to + '"}';
        this.serverService.show.next(api_reqs);
      }

    }
    console.log(to);
  }

  clictToCall2() {
    // if(to == 'phone'){  this.to_num = $('#phone').val(); } else {  this.to_num = $('#mobile').val(); }

    // if(to == 'phone'){  this.to_num = this.click_to_call_number; } else {  this.to_num = this.click_to_call_mobile_number; }
    let to = $('#mobile_phone').val();
    if (to == '') {
      iziToast.warning({
        message: "No Number To Call",
        position: 'topRight'
      });
    } else {


      let access_token: any = localStorage.getItem('access_token');

      var extention = localStorage.getItem('ext_int_status');
      //alert(extention);
      if (extention == '2') {
        let api_reqs: any = '{"type": "makecall", "number": "' + to + '","show_caller_id":"' + this.show_caller_id + '"}';
        this.serverService.show.next(api_reqs);
      } else {
        let api_reqs: any = '{"type": "makecallauto", "number": "' + to + '"}';
        this.serverService.show.next(api_reqs);
      }

    }
    console.log(to);
  }

  addWrapupcode() {
    let wrapcall_id = $('#wrapup_callID').val();
    let cat_id = $('#auxcodes_pop').val();
    let wraupcode = $('#auxcodes_subcat').val();
    let notes = $('#notes').val();
    let access_token: any = localStorage.getItem('access_token');
    let from_no;
    let to_no;
    let queue_ids = $('#queue_ids2').val();
// alert(queue_ids)
// alert(this.Queue_number);
    var wrap = this.category_name + ' -> ' + wraupcode;

    if (this.paramCall == 'incoming') {
      from_no = this.param1;
      to_no = this.extension;
    }
    else {
      from_no = this.extension;
      to_no = this.param1;
    }
    //notes=notes.toString().replace('"',' ');
    //notes = notes.toString().replaceAll(/"|'/g, '');
    notes = notes.replace(/\r?\n|\r/g, " ");
    if(queue_ids==''||queue_ids==null){
     console.log('No Queuename found');
    }
    // alert(from_no)
  console.log(notes)
    let api_reqs: any = '{"type": "updatewrapupCode","call_type": "'+this.paramCall+'","aux_code": "'+wraupcode+'","cat_id": "'+cat_id+'","call_note": "'+notes+'","from_no": "'+from_no+'","to_no": "'+to_no+'","wrapCode": "'+wrap+'"}';
    this.serverService.minimize.next(api_reqs);
return false;  
    
    let api_req: any = new Object;
    let conct_req: any = new Object();

    api_req.operation = "contact";
    api_req.moduleType = "contact";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');

    conct_req.from_no = from_no;
    conct_req.to_no = to_no;
    conct_req.type = this.paramCall;
    conct_req.aux_code = wraupcode;
    conct_req.cat_id = cat_id;
    conct_req.call_note = notes;
    conct_req.call_queue_num = queue_ids;
    conct_req.user_id = this.uadmin_id;
    api_req.element_data = conct_req;


    api_req.element_data.admin_id = this.admin_id;
    api_req.element_data.action = "add_auxcode_wall";
    this.serverService.sendServer(api_req).subscribe((response: any) => {
     
      if (response.status == false) {

      }
    },
      (error) => {
        console.log(error);
      });
    var socket_message = '[{"cust_id":"' + this.has_hard_id + '","data":[{"Name":"wrapupcode","callid":"' + wrapcall_id + '","wcode":"' + wrap + '","wcodenote":"' + notes + '","extno":"' + this.extension + '"}]}]';
    this.websocket.send(socket_message);
    this.closeQuestion();
    iziToast.success({
      message: "Wrapup Code Added successfully",
      position: "topRight"
    });
    $('#auxcodes_pop').val('');
    $('#auxcodes_subcat').val('');
    $('#notes').val('');
  }
  gettimedetails(act_id) {

    let get_single_timeline: any = new Object();
    get_single_timeline.accessToken = this.MDy_bearer_token;
    get_single_timeline.activity_id = act_id;
    get_single_timeline.apiFor = "get_single_timeline";
    this.serverService.MDy_Contacts_API(get_single_timeline).subscribe((response: any) => {

      // if(response.status==true){
      // if (response.data != "") {
        if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
        this.single_timeline = response.data[0];
        let Tdata = response.data[0];
        console.log(this.single_timeline);
        this.Tsubject = Tdata.subject;
        this.Tdescription = Tdata.description;
        this.Tphone = Tdata.phonenumber;
        this.Trecord = Tdata.new_recordingurl;
        this.Twrapcode = Tdata.new_wrapupcode;
        this.Twrapnote = Tdata.new_wrapupnote;
        //  alert(this.Trecord);
        //  this.phone_activity=this.phone_activity.reverse();
        //  console.log(this.phone_activity)
        $('#showpop_timeline').modal('show');
        this.recordNotFound = false;

      } else {
        this.Tsubject = '';
        this.Tdescription = '';
        this.Tphone = '';
        this.Trecord = '';
        this.Twrapcode = '';
        this.Twrapnote = '';
        iziToast.warning({
          message: 'Sorry, cannot fetch timeline details',
          position: 'topRight'
        })
        this.recordNotFound = true;
      }


    },
      (error) => {
        console.log(error);
      });
  }


  closeModelPopup() {

    const modalRef = this.modalService.dismissAll(EditContactsComponent);
    let api_reqs: any = '{"type": "minimize"}';
    this.serverService.minimize.next(api_reqs);
  }

  acceptIncomeCall() {
    localStorage.setItem('ans_click','1');//to remove ans button on popup
    let api_reqtest: any = '{"type": "showpopupdialer"}';
    this.serverService.show.next(api_reqtest);
    let api_reqs: any = '{"type": "attendincomingCall"}';
    this.serverService.minimize.next(api_reqs);
    // this.show_mini_butons = true;
    this.show_ans = false;
    this.show_del = true;
  }

  declineIncomeCall() {
    sessionStorage.removeItem('ans_click');//to remove decline/ans button on popup
    let api_reqs: any = '{"type": "declineincomingCall"}';
    this.serverService.minimize.next(api_reqs);
    // this.show_mini_butons = true;
    this.show_ans = false;
    this.show_del = false;
  }
getTimeline(){
    let api_req_get_access: any = new Object();
    let api_req_get_contact: any = new Object();
    let api_req_get_activity: any = new Object();



   
    // alert(this.incomecallNum);
    console.log(this.incomecallNum);

    api_req_get_access.apiFor = "getAcesstoken";

  this.serverService.MDy_Contacts_API(api_req_get_access).subscribe((response: any) => {
  this.MDy_bearer_token = response.access_token;
  api_req_get_activity.apiFor = "getPhoneCallActivity";
  api_req_get_activity.number = this.incomecallNum;
  api_req_get_activity.accessToken = this.MDy_bearer_token;
  this.serverService.MDy_Contacts_API(api_req_get_activity).subscribe((response: any) => {

    // if(response.status==true){
    // if (response.data != "") {
      if (response.data !=  "" && response.data !=  "null"&& response.data!=  null){
      this.phone_activity = response.data;
  

      this.recordNotFound = false;

    } else {
      this.recordNotFound = true;
      this.phone_activity = [];
    }


  },
    (error) => {
      console.log(error);
    });
});
}
}
