   <div class="section-body">
   	<div class="row">
   		<div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
   			<div class="card">
   				<div class="boxs mail_listing">
   					<div class="inbox-center table-responsive">
   						<table class="table table-hover">
   							<thead>
   								<tr>
   									<th colspan="1">
   										<div class="inbox-header">
   											Reply Message
   										</div>
   									</th>
   								</tr>
   							</thead>
   						</table>
   					</div>
   					<div class="row">
   						<div class="col-lg-12">
   							<div class="composeForm">
   								<div class="form-group">
   									<label for="select-agent">Select Department</label>
   									<select name="Sauces" multiple id="select-agent" class="form-control has-multiple-select">
   										<option value="Sales">Sales</option>
   										<option value="Sales">Support</option>
   										<option value="Sales">Developemnt</option>
   										<option value="Sales">Activation</option>
   										<option value="Sales">UI Develoment</option>
   										<option value="Sales">UX Team</option>
   										<option value="Sales">India Team</option>
   									</select>
   								</div>
   								<div class="form-group">
   									<div class="form-line">
   										<input type="text" id="subject" class="form-control" placeholder="Subject">
   									</div>
   								</div>
   								<div class="form-group">
   									<textarea class="form-control" placeholder="Message" name="editor1"></textarea>
   								</div>

   								<div class="form-group">
   									<div class="compose-editor m-t-20">
   										<div id="summernote"></div>
   										<input type="file" class="default" multiple>
   									</div>
   								</div>

   							</div>
   						</div>
   						<div class="col-lg-12">
   							<div class="m-l-25 m-b-20 btn-group">
   								<button type="button" class="btn btn-info btn-border-radius waves-effect">Send</button>
   								<button type="button" class="btn btn-danger btn-border-radius waves-effect">Discard</button>
   							</div>
   						</div>
   					</div>
   				</div>
   			</div>
   		</div>

   	</div>
   </div>