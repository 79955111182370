
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>SMS Templates</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addDepartment()">+ Add Template</button>
                    </div>
					<a href="https://assaabloycc.mconnectapps.com/api/v1.0/pro_contents/pro_sms_temp.pdf" target="_blank"><i style="font-size:24px;padding-left: 20px;" class="fa fa-exclamation-circle"></i></a>

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                       <table class="table table-bordered table-striped table-md">
                         
                        <tr>
                            <th>S.no </th>
                            <th>Queue No</th>
                            <th>Template</th>
                            <th>Action</th>
                        </tr>

                        <tbody>
                            <tr *ngFor="let queue of queue_list; let i=index">
                                <td>{{0+i+1}}</td>
                                <td>{{queue.queue_no}}</td>
                                <td>{{queue.template_message}}</td>
                                
                                    <td>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editDepartmentSettings(queue.template_id)"></i></span>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.template_id)"></i></span>
                                    </td>
                                </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                     
                 </table>
             </div>
         </div>
         
     </div>
 </div>
</div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editDepartment(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Template</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">


                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="department">Select Queue</label>
                                <select name="Upd_queue_no" id="Upd_queue_no"  class="form-control">
                                    <option *ngFor="let dept of Mqueue_list"  value="{{dept.queue_number}}">{{dept.queue_name}}</option>
                                </select>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Template</label>
                                <textarea id="template" class="form-control" formControlName="template"></textarea>                            </div>
                        </div>
                        
                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Submit" [disabled]="!editDept.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Template</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">


                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="department">Select Queue</label>
                                <select name="queue_no"  id="queue_no"  class="form-control">
                                    <option *ngFor="let dept of Mqueue_list" value="{{dept.queue_number}}">{{dept.queue_name}}</option>
                                </select>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Template</label>
                                <textarea id="template" class="form-control" formControlName="template"></textarea>
                            </div>
                        </div>

                        

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Submit" [disabled]="!addDept.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>