import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrls: ['./schedule-report.component.css']
})
export class ScheduleReportComponent implements OnInit {

  call_history_list;
  recordNotFound = false;
  pageLimit = 20;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  getRep: FormGroup;
   admin_id;
  res;
  timerange=false;
  input_abn=false;
  inputtype='date';
  get_queues;
  agents_list;
  agent_filter;
  message;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
    this.admin_id=localStorage.getItem('admin_id');

    this.callHistoryList({});    
    // this.agent_list();
    this.getRep = new FormGroup({
      'type' :new FormControl(null),
      'rep_format' :new FormControl(null),
      'report_name' :new FormControl(null),
      'email_to' :new FormControl(null),
      // 'from_dt' :new FormControl(null),
      // 'to_dt' :new FormControl(null),
      'rep_requency' : new FormControl(null),
      'ranges' : new FormControl(null)
    });
  }
  agent_list(){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"view_agent_group","admin_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
       
        this.agents_list = response.result.data;
        
      } else {
        this.recordNotFound = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		list_data.order_by_name = list_data.order_by_name == undefined ? "history.report_details_id" : list_data.order_by_name;
		list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
	}
  callHistoryList(data){

  var list_data= this.listDataInfo(data);
  let api_req:any = new Object();
  let history_req:any = new Object();
  history_req.action="list_report_schedule";
  // history_req.search_text=list_data.search_text;
  // history_req.order_by_name=list_data.order_by_name;
  // history_req.order_by_type=list_data.order_by_type;
  history_req.limit=list_data.limit;
  history_req.offset=list_data.offset;
  history_req.admin_id=localStorage.getItem('admin_id');
  api_req.operation="agents";
  api_req.moduleType="agents";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = history_req;
        this.serverService.sendServer(api_req).subscribe((response:any) => {
        
          if(response.status=='true'){
          
            this.call_history_list=response.data;
            this.offset_count = list_data.offset;
            this.paginationData = this.serverService.pagination({'offset':response.offset, 'total':response.total, 'page_limit' :this.pageLimit });
            this.recordNotFound = this.call_history_list.length == 0 ? true : false;
          }
            
        }, 
        (error)=>{
            console.log(error);
        });

}
   genReport(){
      let access_token: any=localStorage.getItem('access_token');
      // let report_details:any = this.getRep.value;
      let report_details:any = new Object();

 
console.log(this.getRep.value.rep_requency)
console.log(this.getRep.value.type)
if(this.getRep.value.rep_requency == ""||this.getRep.value.rep_requency ==null){
  iziToast.error({
    message: "Select report frequency",
    position: 'topRight'
  });
  return false;
 }
 if(this.getRep.value.type == ""||this.getRep.value.type ==null){
  iziToast.error({
    message: "Select report type",
    position: 'topRight'
  });
  return false;
 }
//  if(this.getRep.value.from_dt == ''||this.getRep.value.from_dt ==null){
//   iziToast.error({
//     message: "Select From Date",
//     position: 'topRight'
//   });
//   return false;
//  }
//  if(this.getRep.value.to_dt == ""||this.getRep.value.to_dt ==null){
//   iziToast.error({
//     message: "Select To Date",
//     position: 'topRight'
//   });
//   return false;
//  }

 if(this.getRep.value.ranges == ""||this.getRep.value.ranges ==null){
  iziToast.error({
    message: "Select report Range",
    position: 'topRight'
  });
  return false;
 }
 
// if(this.getRep.value.rep_requency != "one_time_now"){
  // if($('#report_time').val()==null ||$('#report_time').val()==''){
  //   iziToast.error({
  //     message: "please assign a time to send the report",
  //     position: 'topRight'
  //   });
  //   return false;
  // }
// }
if(this.getRep.value.report_name == ""||this.getRep.value.report_name ==null){
  iziToast.error({
    message: "Please enter the report name",
    position: 'topRight'
  });
  return false;
 }

 if($('#email_to').val()=='' ||$('#email_to').val()==null ){

  iziToast.error({
    message: "Please Email to send the report",
    position: 'topRight'
  });
  return false; 
 }
    // var from_time=  $('#from_time').val();
    // var to_time=  $('#to_time').val();
    // var from_dateParts = this.getRep.value.from_dt.split('-');
    // var to_dateParts = this.getRep.value.to_dt.split('-');
    //$('#persons option').prop('selected', true);
   
   


      //this.getRep.value.queue.join();
    


   
    //  from_time = from_time.split(':');
    //  to_time = to_time.split(':');
  

    // if(from_dateParts && from_time) {
    //   from_dateParts[1] -= 1;
    //   to_dateParts[1] -= 1;
    //   this.getRep.value.from_dt = new Date(Date.UTC.apply(undefined,from_dateParts.concat(from_time))).toISOString().replace('Z', ' ').replace('T', ' ');
    //   this.getRep.value.to_dt = new Date(Date.UTC.apply(undefined,to_dateParts.concat(to_time))).toISOString().replace('Z', ' ').replace('T', ' ');
    // }
    // $('#Qroup_id').val('')
	    let api_req:any = new Object();
			report_details.action="add_report_schedule";
			report_details.admin_id = localStorage.getItem('admin_id');
			report_details.user_id = localStorage.getItem('userId');
			report_details.type=this.getRep.value.type;
			report_details.name=this.getRep.value.report_name;
			report_details.frequency=this.getRep.value.rep_requency;
			report_details.range=this.getRep.value.ranges;
			// report_details.time_val=$('#report_time').val();
			// report_details.from_val=this.getRep.value.from_dt;
			// report_details.to_val=this.getRep.value.to_dt;
      report_details.email=$('#email_to').val(); 
			api_req.operation="agents";
			api_req.moduleType="agents";
			api_req.api_type="web";
			api_req.access_token=localStorage.getItem('access_token');
      api_req.element_data = report_details;
       
     console.log(api_req)
  //  return false
   Swal.fire({
    html:
      '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
  showCloseButton: false,
    showCancelButton: false,
    showConfirmButton: false,
    focusConfirm: false,
    background: 'transparent'
  });
      this.serverService.sendServer(api_req).subscribe((response:any) => {
        Swal.close();
        if(response.result.data == 1){     
          this.callHistoryList({});
          iziToast.success({
            message: "Report Added Successfully",
            position: 'topRight'
        });
        this.getRep.reset();
        } else {
          iziToast.error({
            message: "Some Error occured",
            position: 'topRight'
        });
        }        
        this.getRep.value.reset();
      
      }, 
      (error)=>{
          console.log(error);
      });



 
			
 
 
    }

    onOptionsSelected(value:string){

      if(value =='daily'){
        this.message="The report will be sent daily";
      }else if(value=='monthly'){
        this.message="Report will be sent on the last day of the month";
      }else{
        this.message="Report will be sent on the last day of the week(Friday)";
      }
    if(value != 'one_time_now'){       
      this.timerange= true;
    
      }else{
      this.timerange= false;
     
      } 
     
 } 

 onOptionRangeSelected(value:string){

 
} 


 makeAction(id, events) {


  let checks:any;
   if(events.target.checked == true){
    checks = 0;
  }else if(events.target.checked == false){
     checks = 2;
   }


  let access_token: any = localStorage.getItem('access_token');
  let api_req: any = '{"operation":"toggle_report_schedule", "moduleType": "agents", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"toggle_report_schedule","admin_id":"' + this.admin_id + '","id":"' + id + '", "value":"'+checks+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    
    if (response.result.data == true) {
      Swal.fire(
      'updated!',
      'success'
    );
    }else{
      Swal.fire(
        'oops!',
        'error'
      );
    }
  },
    (error) => {
      console.log(error);
    });
}
}