
<div class="section-body">
    <div class="row">
        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Chat Rating Report</h4>
                  
                </div>
                <div class="card-body p-30">
                <form >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_date">From Date</label>
                                <input type="date" id="from_date" class="form-control">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="to_date">To Date</label>
                                <input type="date" id="to_date" class="form-control" >
                            </div>
                        </div>

                    </div>

                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <!-- <button type="button" class="btn btn-primary" (click)="searchDatalist({})">Custom Search</button> -->

                                <button type="button" class="btn btn-primary" (click)="exportcsv()">Generate Report</button>
                            </div>
                        </div>
                    </div>
                    
                </form>
                </div>
            </div>
            
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Chat Ratings</h4>
                    <!-- <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="exportcsv()"> Export as CSV</button>
                    </div> -->
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                       <table class="table table-bordered table-striped table-md">
                        <tr>
                            <td colspan="12" class="search-text-tbl">
                                <div class="input-group"><input type="text" class="form-control" placeholder="Search Agent or Customer Name/Country" (keyup.enter)="searchData({'search_text':call_history_search.value})" #call_history_search>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" (click)="searchData({'search_text':call_history_search.value})"><i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Customer</th>
                            <th>Chat Agent</th>
                            <th>Chat Date</th>
                            <th>Chat Department</th>
                            <th>Chat Rating</th>
                            <th>Chat Country</th>
                        </tr>

                        <tbody>
                            <tr *ngFor="let queue of chat_panel_list; let i=index">
                                <td>{{offset_count+i+1}}</td>
                                <td>{{queue.customer_name}}</td>
                                <td>{{queue.chat_agent}}</td>
                                <td>{{queue.chat_dt}}</td>
                                <td>{{queue.department_name}}</td>
                                <td>{{queue.rating_value}}</td>
                                    <td>
                                        {{queue.country}}
                                    </td>
                                </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                     
                 </table>
             </div>
         </div>
         <div class="card-footer text-right" *ngIf="recordNotFound == false">
            <nav class="d-inline-block">
            <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                    <li class="page-item">
                        <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? searchData({'search_text':call_history_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li class="page-item" *ngIf="paginationData.backtab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="searchData({'search_text':call_history_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                    </li>


                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                        <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? searchData({'search_text':call_history_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                    </li>

                    <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="searchData({'search_text':call_history_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                    </li>

                    <li class="page-item">
                        <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? searchData({'search_text':call_history_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                    </li>
            </ul>
        </nav>
    </div>
     </div>
 </div>
</div>
</div>