
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Questions</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addQuestion()">+ Add Question</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                     <table class="table table-bordered table-striped table-md">
                        <tr>
                            <th>S.no </th>
                            <th>Question ID</th>
                            <th>Question</th>
                            <th>Action</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let queue of question_list; let i=index">
                                <td>{{0+i+1}}</td>
                                <td>{{queue.id}}</td>
                                <td>{{queue.question}}</td>

                                <td>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editQuestionSettings(queue.id)"></i></span>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.id)"></i></span>
                                </td>
                            </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                 </table>
             </div>
         </div>

     </div>
 </div>
</div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editQuestion(ques_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Question</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                   <div class="row">
                    <div class="col-md-12">
                       <h6>Question</h6>
                   </div>
               </div>

               <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" id="question_name" class="form-control" formControlName="question_name" >
                    </div>
                </div>
            </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="question_queues">Select QuestionUsers</label>
                                <input type="text" id="question_queues" class="form-control" formControlName="question_queues">
                            </div>
                            
                        </div> -->

                        <!-- <div class="col-md-12">
                            <div class="form-group" >
                                <label for="question_name">Queues</label>
                                <div *ngFor="let queue of queue_list">
                                    <label for="listusers" >{{queue.queue_name}}</label>
                                    <input type="checkbox" [attr.checked]="userchecked && userchecked.indexOf(queue.queue_id) == -1 ? checked : ''"  class="ads_Checkbox" value="{{queue.queue_id}}" name="listusers[]">
                                </div>
                            </div>
                        </div>   -->
                        <div class="row">
                            <div class="col-md-12">
                               <h6>Queues</h6>
                           </div>
                       </div>


                       <div class="row has-row-space">
                        <div class="col-md-12">
                            <div class="checkbox-row" *ngFor="let queue of queue_list">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" [attr.checked]="userchecked && userchecked.indexOf(queue.queue_id) == -1 ? true : ''"  class="ads_Checkbox" value="{{queue.queue_id}}" name="listusers[]">
                                        <div class="state p-success">
                                            <label for="listusers">{{queue.queue_name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editDept.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addQuestData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Question</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Question</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="question_name" class="form-control" formControlName="question_name" >
                            </div>
                        </div>
                    </div>

                        <!-- <div class="col-md-12">
                            <div class="form-group" >
                                <label for="question_name">Queues</label>
                                <div class="checkbox-row" *ngFor="let queue of queue_list">
                                    <label for="listusers">{{queue.queue_name}} </label>
                                    <input type="checkbox"  class="add_Checkbox" value="{{queue.queue_id}}" name="addlistusers[]">
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <h6>Queues</h6>
                            </div>
                        </div>

                        <div class="row has-row-space">
                            <div class="col-md-12">
                                <div class="checkbox-row" *ngFor="let queue of queue_list">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox"  class="ads_Checkbox" value="{{queue.queue_id}}" name="addlistusers[]">  
                                            <div class="state p-success">
                                                <label for="listusers">{{queue.queue_name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="status">Status</label>
                                    <input type="checkbox" id="status" class="form-control" formControlName="status">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="buttons">
                            <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                            <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!addDept.valid">
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>